import React from "react";
import { Panel, Glyphicon } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import MultiValueForm from "../Elements/FromGroup/MultiValueForm";
import AscComponent from "../../components/AscComponent";
import * as GlobalConst from "../../components/AscConstants";
import MultiSelect from "../Elements/Select/MultiSelect";
import {components} from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";

export default class BusinessManagementModal extends AscComponent {
    setBusinessModalItem = () => {
        let { state, langText, charaLimit, onTextChange_Limit, onMultiSelectChange, propSetState } =
            this.props;

        let itemArr = [];

        if (state.modalType === "insert" || state.modalType === "update") {
            // 会社選択のプルダウン(システム管理者以外は会社選択のプルダウンを表示しない)
            if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                if (state.modalType === "insert") {
                    itemArr.push(
                        <InlineForm
                            key="company_id"
                            controlId="company_id"
                            label={
                                this.props.labelDisabled
                                    ? null
                                    : this.props.langText.Body.CompanyName
                            }
                            type="select"
                            onChange={(e) => {
                                this.props.onSelectChange(e, "company_id");
                                this.props.onChange(e, "company_id");
                            }}
                            options={state.companySelect}
                            value={state.company_id}
                            isClearable={true}
                            validationState={state.company_id ? null : "error"}
                            placeholder={
                                !state.companySelect[0] ? (
                                    <Glyphicon glyph="refresh" className="spinning" />
                                ) : (
                                    this.props.langText.SelectOption.Placeholder
                                )
                            }
                        />
                    );
                } else if (state.modalType === "update") {
                    itemArr.push(
                        <InlineForm.static
                            key="company_name"
                            controlId="company_name"
                            label={langText.Body.CompanyName}>
                            {state.company_name}
                        </InlineForm.static>
                    );
                }
            }

            // 業務名入力テキストボックス
            itemArr.push(
                <InlineForm
                    key="business_name"
                    controlId="business_name"
                    label={langText.Body.BusinessName}
                    type="text"
                    value={state.business_name}
                    onChange={(e) =>
                        this.props.onTextChange_Limit(
                            e,
                            "business_name",
                            this.props.charaLimit.Task_Name
                        )
                    }
                    validationState={state.business_name ? null : "error"}
                />
            );

            // 拠点、およびフロアの割り当て
            itemArr.push(
                <InlineForm
                    key="selected_departments"
                    label={langText.MultiSelect.DepartmentSelect}>
                    <MultiSelect
                        key="selected_departments"
                        controlId="selected_departments"
                        propSetState={propSetState}
                        multiSelectText={langText.MultiSelect}
                        searchText={langText.MultiSelect.DepartmentAndFloorSearch}
                        options={state.department_select}
                        value={state.selected_departments}
                        type={state.modalType}
                        isDisabled={state.modalType === "delete" || state.modalType === "read"}
                        multiSelectMessage={langText.MultiSelect.SelectDepartmentDataNumStr_NDelete}
                        multiSelectDeleteMessage={
                            langText.MultiSelect.SelectDepartmentDataNumStr_Delete
                        }
                    />
                </InlineForm>
            );

            // 外線番号選択のプルダウン
            const customOption = (props) => {
                return (
                    <div>
                        <components.Option {...props}>
                            {props.data.label}
                            {
                                props.data.analysis &&
                                <FontAwesomeIcon 
                                    className="margin-left-05"
                                    icon={faIcon.faChartPie}
                                />
                            }
                        </components.Option>
                    </div>
                );
            }

            itemArr.push(
                <InlineForm
                    key="external_numbers"
                    controlId="external_numbers"
                    label={<SetDataWithSpeechBubble 
                        displayData = {langText.Body.ExternalNumber} 
                        speechBubbleData = {
                            <div>
                                <FontAwesomeIcon 
                                    className="margin-left-05"
                                    icon={faIcon.faChartPie}
                                />
                                {langText.Message.CallAnalysisIconDescription}
                            </div>
                        }
                        infoIconDisplayFlag = {true}
                        infoIconType = {faIcon.faInfoCircle}
                        infoIconClassName = "modal-info-icon"
                        infoIconColor = "silver"
                        infoIconSize = "lg"/>}
                    type="select"
                    value={state.external_numbers}
                    onChange={(e) => onMultiSelectChange(e, "external_numbers")}
                    options={state.externalarr}
                    components={{ Option: customOption }}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    isClearable={true}
                    placeholder={langText.SelectOption.Placeholder}
                    isDisabled={!state.company_id}
                />
            );

            // メモ入力のテキストボックス
            itemArr.push(
                <InlineForm
                    key="memo"
                    controlId="memo"
                    label={langText.Body.Memo}
                    type="text"
                    value={state.memo}
                    onChange={(e) => onTextChange_Limit(e, "memo", charaLimit.Task_Memo)}
                />
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            // システム管理者の場合、会社名を表示する
            if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                itemArr.push(
                    <InlineForm.static
                        key="company_name"
                        controlId="company_name"
                        label={langText.Body.CompanyName}>
                        {state.company_name}
                    </InlineForm.static>
                );
                let taskDepartments = [];
                state.task_departments.forEach(obj => {
                    taskDepartments.push(<p>{obj.value}</p>);
                });
                itemArr.push(
                    <InlineForm.static
                        key="task_departments"
                        controlId="task_departments"
                        label={langText.Body.AssignDepartmentAndFloor}>
                        {taskDepartments}
                    </InlineForm.static>
                );
            }
            itemArr.push(
                <InlineForm.static
                    key="task_name"
                    controlId="task_name"
                    label={langText.Body.BusinessName}>
                    {state.task_name}
                </InlineForm.static>
            );
            let externalNumbers = [];
            state.task_external_numbers.forEach(obj => {
                externalNumbers.push(<p>{obj.value}</p>);
            });
            itemArr.push(
                <InlineForm.static
                    key="del_external_numbers"
                    controlId="del_external_numbers"
                    label={langText.Body.ExternalNumber}>
                    {externalNumbers}
                </InlineForm.static>
            );
            let operators = [];
            state.task_operators.forEach(obj => {
                operators.push(<p>{obj.value}</p>);
            });
            itemArr.push(
                <InlineForm.static
                    key="del_operators"
                    controlId="del_operators"
                    label={langText.Body.OperatorId}>
                    {operators}
                </InlineForm.static>
            );
            itemArr.push(
                <InlineForm.static key="task_memo" controlId="task_memo" label={langText.Body.Memo}>
                    {state.task_memo}
                </InlineForm.static>
            );
        }
        return itemArr;
    };

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Body>{this.setBusinessModalItem()}</Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
