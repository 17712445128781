import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Col, ControlLabel, Glyphicon} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import DatePicker from "react-datepicker";
import "../Elements/FromGroup/form_select.css"
import moment from "moment";


export default class ExternalNumberScheduleExtensionModal extends Component {

    getScheduleExtensionInfoItem() {
        let {
            state,
            langText,
            systemParameters,
            validationHandle,
            onDateChangeHandle
        } = this.props;

        let itemArr = [];
        if(!state.date_validation){
            itemArr.push(
                <div
                    key = "schedule-extension-validation"
                    className = "schedule-extension-validation"
                >
                    {langText.Message[state.validate_msg]}
                </div>
            );
        }
        itemArr.push(
            <InlineForm
                key = "start-datetime"
                label = {langText.Body.ExtensionStartDateTime}
                componentClass = {ControlLabel}
            >
                <Col>
                    <span className="start-datetime">
                        <DatePicker 
                            controlId = "start_datetime" 
                            selected = {state.start_datetime} 
                            onChange = {e => onDateChangeHandle(e, 'start_datetime')}
                            showTimeSelect
                            timeFormat = "HH:mm"
                            timeIntervals = {1}
                            timeCaption = {langText.Body.Time}
                            dateFormat = "YYYY-MM-DD HH:mm"
                            readOnly = {false}
                            minDate = {moment({h:0, m:0, s:0, ms:0})}
                        />
                    </span>
                </Col>
            </InlineForm>
        );

        itemArr.push(
            <InlineForm
                key = "end-datetime"
                label = {langText.Body.ExtensionEndDateTime}
                componentClass = {ControlLabel}
            >
                <Col>
                    <span className="end-datetime">
                        <DatePicker 
                            controlId = "end_datetime" 
                            selected = {state.end_datetime} 
                            onChange = {e => onDateChangeHandle(e, 'end_datetime')}
                            showTimeSelect
                            timeFormat = "HH:mm"
                            timeIntervals = {1}
                            timeCaption = {langText.Body.Time}
                            dateFormat = "YYYY-MM-DD HH:mm"
                            readOnly = {false}
                            minDate = {moment(state.start_datetime)}
                            maxDate = {moment(state.start_datetime).add(1,'weeks')}
                        />
                    </span>
                </Col>
            </InlineForm>
        );

        itemArr.push(
            <InlineForm
                key = "schedule_extenxion_group"
                controlId = "schedule_extenxion_group"
                label = {langText.Body.InboundGroupName}
                type = "select"
                onChange = {e => this.props.onMultiSelectChangeExternal(e, "schedule_extenxion_group")}
                options = {state.inboundGroupSelect}
                value = {state.schedule_extenxion_group}
                isClearable = {true}
                validationState = {validationHandle("schedule_extenxion_group")}
                isMulti = {true}
                closeMenuOnSelect={false}
                placeholder = {this.props.propState.buttondisabledArr.inbound_group_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                isDisabled = {!this.props.propState.buttondisabledArr.inbound_group_id[0]}
                />
        );
        let drawdata = '';
        let drawcolor = 'black';
        if(state.inboundGroupcount > systemParameters.EXTEN_COUNT)
        {
            drawdata = state.inboundGroupcount + " / " + systemParameters.EXTEN_COUNT + " " + langText.Body.ExtenCountOver_1 + systemParameters.EXTEN_COUNT + langText.Body.ExtenCountOver_2;
            drawcolor = 'red';
        }
        else
        {
            drawdata = state.inboundGroupcount + " / " + systemParameters.EXTEN_COUNT;
            drawcolor = 'black';
        }
        itemArr.push(
            <InlineForm.static
                key = "exten_count"
                controlId = "exten_count"
                label = {langText.Body.ExtenCount}>
                    <font color={drawcolor}>
                        {drawdata}
                    </font>
            </InlineForm.static>
        )

        return itemArr
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.IrregularSetting}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getScheduleExtensionInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
