import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import params from "../AscParams.json";
import selectOption from "../containers/Elements/SelectOption/SelectOption";
import { faGrinTongueSquint } from "@fortawesome/free-solid-svg-icons";
import * as GlobalConst from "./AscConstants";
import CompanyControlParametersOption from "../containers/Elements/CompanyControlParametersOption/CompanyControlParametersOption";
import { toast } from "react-hot-toast";

const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
};

const blockUI = () => {
    let blockDiv = document.getElementById("asc-block-ui");

    if (blockDiv) {
        blockDiv.classList.remove("asc-display-none");
    }
};

const unblockUI = () => {
    let blockDiv = document.getElementById("asc-block-ui");

    if (blockDiv) {
        blockDiv.classList.add("asc-display-none");
    }
};

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = params.baseURL;
axios.defaults.timeout = 60 * 1000;

axios.interceptors.response.use(
    (res) => {
        if (typeof res.data === "string") {
            try {
                let base64DecodingData = JSON.parse(b64DecodeUnicode(res.data));
                res.data = base64DecodingData;
            } catch (e) {}
        }

        unblockUI();
        return res;
    },
    (err) => {
        try {
            if (typeof err.response.data === "string") {
                try {
                    let base64DecodingData = JSON.parse(b64DecodeUnicode(err.response.data));
                    err.response.data = base64DecodingData;
                } catch (e) {}
            }
        } catch (err) {}

        if (
            (err.status && err.status === 401) ||
            (err.response && err.response.status && err.response.status === 401)
        ) {
            //alert('sessionが切れました。\r\n画面を作り直します。');
            alert("ログアウトされました。");
            window.location.reload();
        }

        unblockUI();
        return Promise.reject(err);
    }
);

export default class AscComponent extends Component {
    constructor(props) {
        super(props);

        this.table = React.createRef();
        this.baseURL = params.baseURL;
        this.onTextChange = this.onTextChange.bind(this);
        this.onTextChange_Limit = this.onTextChange_Limit.bind(this);
        this.onIntChange = this.onIntChange.bind(this);
        this.onKeyDownIntCheck = this.onKeyDownIntCheck.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onNestSetState = this.onNestSetState.bind(this);
        this.onMultiSelectChange = this.onMultiSelectChange.bind(this);
        this.onMultiSelectChangeExternal = this.onMultiSelectChangeExternal.bind(this);
        this.onMultiTelNumberChangeLimit = this.onMultiTelNumberChangeLimit.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.onCheckBoxChange_Init = this.onCheckBoxChange_Init.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onNestCheckBoxChange = this.onNestCheckBoxChange.bind(this);
        this.setCommonCompanySelect = this.setCommonCompanySelect.bind(this);
        this.getCommonCompanySelect = this.getCommonCompanySelect.bind(this);
        this.setCommonDepartmentSelect = this.setCommonDepartmentSelect.bind(this);
        this.getCommonDepartmentSelect = this.getCommonDepartmentSelect.bind(this);
        this.setCommonFloorSelect = this.setCommonFloorSelect.bind(this);
        this.getCommonFloorSelect = this.getCommonFloorSelect.bind(this);
        this.getScopeGreaterEqual = this.getScopeGreaterEqual.bind(this);
        this.fetchData = this.reactTableFetchData.bind(this);
        this.execFullTextSearch = this.execFullTextSearch.bind(this);
        this.getScriptUsingCalendarName = this.getScriptUsingCalendarName.bind(this);
        this.getSummary = this.getSummary.bind(this);
        this.blockUI = blockUI;
        this.unblockUI = unblockUI;
        this.saveAccessLogs = this.saveAccessLogs.bind(this);
        this.passwordValidationHandle = this.passwordValidationHandle.bind(this);
        this.toast = this.toast.bind(this);

        if (props.location) {
            this.reactContainerPath = props.location.pathname;
            this.reactTableTarget = props.location.pathname + "/board";
        }
    }

    propSetState = (state) => {
        this.setState(state);
    };

    onTextChange(event, param) {
        let value = event && event.target && event.target.value ? event.target.value : "";
        this.setState({ [param]: value });
    }

    onTextChange_Limit(event, param, limitcount) {
        let value = event && event.target && event.target.value ? event.target.value : "";
        if (value.length <= limitcount) {
            this.setState({ [param]: value });
        }
    }

    /**
     * 電話番号複数入力のチェンジイベント
     *
     * @param {String} param
     * @param {Integer} limitCount
     */
    onMultiTelNumberChangeLimit(event, param, limitCount) {
        const inputBackNumbers = event;
        if (inputBackNumbers.length === 0) {
            // データクリア
            this.setState({ [param]: [] });
            return false;
        }

        const eventPosition = inputBackNumbers.length - 1;
        const backNumber = inputBackNumbers[eventPosition].value.replace(/[^0-9]+/g, "");
        // 数値のみを取り出してデータを表示させるため入力値は削除
        inputBackNumbers.splice(eventPosition, 1);

        if (!backNumber || backNumber.length > limitCount) return false;

        // 重複データがあり
        if (inputBackNumbers.findIndex(i => i.value === backNumber) >= 0) return false;

        inputBackNumbers.push({ label: backNumber, value: backNumber });
        this.setState({ [param]: inputBackNumbers });
    }

    onIntChange = (param, maxlength) => (event) => {
        if (event.target.value.length <= maxlength) {
            let value = event ? event.target.value : "";
            value = value.replace(/[^0-9]+/i, "");
            if (value != "") {
                value = Number(value);
            }
            this.setState({ [param]: value });
        }
    };
    onKeyDownIntCheck = (e) => {
        if (
            (e.keyCode >= 48 && e.keyCode <= 57) ||
            (e.keyCode >= 96 && e.keyCode <= 105) ||
            e.keyCode === 8 ||
            e.keyCode === 46 ||
            e.keyCode === 9 ||
            e.keyCode === 16 ||
            (e.keyCode >= 37 && e.keyCode <= 40)
        ) {
            return true;
        } else if (e.ctrlKey) {
            if (e.keyCode === 67 || e.keycode === 86) {
                return true;
            }
        } else {
            e.preventDefault();
            return false;
        }
    };

    onSelectChange(event, param) {
        let value = event && event.value ? event && event.value : "";
        this.setState({ [param]: value });
    }

    /**
     * 編集または削除するスクリプトが使用中かチェック
     * @param {string} cm70_id
     * @returns {promise <array>} UsingList
     */
    async getScriptUsingCalendarName(cm70_id) {
        let cm32UsingList = Array();
        let cm33UsingList = Array();
        let UsingList = Array();
        const yesterday = this.getMomentTime({
            format: "YYYY/MM/DD",
            date: moment().add(-1, "days")
        });

        await this.ascAxios("post", `Script/CheckCm32ScriptUsed`, { cm70_id })
            //cm32で使用中のスクリプトを取得
            .then((res) => {
                cm32UsingList = res.data;
            });

        await this.ascAxios("post", `Script/CheckCm33ScriptUsed`, {
            cm70_id,
            irregularDate: yesterday
        })
            //cm33で使用中のスクリプトを取得
            .then((res) => {
                cm33UsingList = res.data;
            });

        // cm32で取得したデータにカレンダータイプを追加
        UsingList = cm32UsingList.map((cm32UsingItem) => ({
            cm31_id: cm32UsingItem.cm31_id,
            business_calendar_name:
                cm32UsingItem.cm31_business_calendar_infos.business_calendar_name,
            type: this.props.langText.Body.BusinessHourSetting
        }));

        // cm33で取得したデータにカレンダータイプを追加、cm32データのマージ
        UsingList = UsingList.concat(
            cm33UsingList.map((cm33UsingItem) => ({
                cm31_id: cm33UsingItem.cm31_id,
                business_calendar_name:
                    cm33UsingItem.cm31_business_calendar_infos.business_calendar_name,
                type: this.props.langText.Body.IrregularSetting
            }))
        );

        return UsingList;
    }

    /**
     * 電話番号入力チェック
     * @param param
     * @returns {boolean} true:OK/false:エラー
     */
    validateTelNumber(param) {
        let max = this.props.langText.Value.tel_number.max;
        let min = this.props.langText.Value.tel_number.min;
        let validTelNo = new RegExp(`[0-9]{${min},${max}}`);

        return validTelNo.test(param);
    }

    /**
     * 重複する値を抽出する
     * @param {Array} checkArray チェックする配列
     * @returns {Array} duplicateItem
     */
    pickUpDuplicate = (checkArray) => {
        const duplicateItem = checkArray.filter((x, i, self) => {
            return self.indexOf(x) === i && i !== self.lastIndexOf(x);
        });
        return duplicateItem;
    };

    /**
     * 編集または削除するスクリプトが使用中かチェック
     * @param {string} cm70_id
     * @returns {promise <array>} UsingList
     */
    async getScriptUsingCalendarName(cm70_id) {
        let cm32UsingList = Array();
        let cm33UsingList = Array();
        let UsingList = Array();
        const yesterday = this.getMomentTime({
            format: "YYYY/MM/DD",
            date: moment().add(-1, "days")
        });

        await this.ascAxios("post", `Script/CheckCm32ScriptUsed`, { cm70_id })
            //cm32で使用中のスクリプトを取得
            .then((res) => {
                cm32UsingList = res.data;
            });

        await this.ascAxios("post", `Script/CheckCm33ScriptUsed`, {
            cm70_id,
            irregularDate: yesterday
        })
            //cm33で使用中のスクリプトを取得
            .then((res) => {
                cm33UsingList = res.data;
            });

        // cm32で取得したデータにカレンダータイプを追加
        UsingList = cm32UsingList.map((cm32UsingItem) => ({
            cm31_id: cm32UsingItem.cm31_id,
            business_calendar_name:
                cm32UsingItem.cm31_business_calendar_infos.business_calendar_name,
            type: this.props.langText.Body.BusinessHourSetting
        }));

        // cm33で取得したデータにカレンダータイプを追加、cm32データのマージ
        UsingList = UsingList.concat(
            cm33UsingList.map((cm33UsingItem) => ({
                cm31_id: cm33UsingItem.cm31_id,
                business_calendar_name:
                    cm33UsingItem.cm31_business_calendar_infos.business_calendar_name,
                type: this.props.langText.Body.IrregularSetting
            }))
        );

        return UsingList;
    }

    /**
     * stateでネストされている値を変更する
     * param キー
     * param2 ネストされているキー
     */
    onNestSetState(event, param, param2) {
        this.setState({ [param]: { ...this.state[param], [param2]: event.target.value } });
    }

    /**
     * 権限管理
     * stateでネストされているチェックボックスの値を切り替える
     */
    onNestCheckBoxChange(event, param, param2) {
        if (param2 === "all") {
            // 全権限操作時は全て連動
            this.setState({
                [param]: {
                    ...this.state[param],
                    all: !this.state[param].all,
                    edit: !this.state[param].all,
                    read: !this.state[param].all,
                    create: !this.state[param].all,
                    delete: !this.state[param].all,
                    export: !this.state[param].all,
                    playback: !this.state[param].all
                }
            });
        } else if (param2 === "read" && this.state[param][param2]) {
            // 閲覧がfalseになった場合は全てfalse
            this.setState({
                [param]: {
                    ...this.state[param],
                    all: false,
                    edit: false,
                    read: false,
                    create: false,
                    delete: false,
                    export: false,
                    playback: false
                }
            });
        } else if (this.state[param][param2]) {
            // 何かがfalseになった場合全権限も必ずfalse
            this.setState({ [param]: { ...this.state[param], [param2]: false, all: false } });
        } else if (
            !this.state[param][param2] &&
            !this.state.all &&
            Object.values(this.state[param]).filter((v) => v === false).length === 2
        ) {
            // 何かがtrueになりall以外全てがtrueになる場合はallをtrueにする
            this.setState({ [param]: { ...this.state[param], [param2]: true, all: true } });
        } else if (param2 !== "read" && !this.state[param][param2]) {
            // 何かがtrueになった場合は閲覧も必ずtrue
            this.setState({ [param]: { ...this.state[param], [param2]: true, read: true } });
        } else {
            this.setState({
                [param]: { ...this.state[param], [param2]: !this.state[param][param2] }
            });
        }
    }

    /**
     * 会社一覧取得Commonコントローラー版
     */
    async setCommonCompanySelect(reactContainerPath) {
        try {
            let result = await this.ascAxios("post", `Common/companySelect`, {
                container: reactContainerPath
            });
            this.setState({ companySelect: result.data });
        } catch (err) {
            throw err;
        }
    }

    /**
     * 会社一覧取得Commonコントローラー版
     */
    async getCommonCompanySelect(reactContainerPath) {
        try {
            return await this.ascAxios("post", `Common/companySelect`, {
                container: reactContainerPath
            });
        } catch (err) {
            throw err;
        }
    }

    async setCommonDepartmentSelect(container) {
        try {
            let result = await this.ascAxios("post", `Common/departmentSelect`, container);
            this.setState({ departmentSelect: result.data, department_id: result.data });
            let value_arr = result.data.map((department) => department.value);
            return value_arr;
        } catch (err) {
            throw err;
        }
    }

    async getCommonDepartmentSelect(container) {
        try {
            return await this.ascAxios("post", `Common/departmentSelect`, container);
        } catch (err) {
            throw err;
        }
    }

    async setCommonFloorSelect(container) {
        try {
            let result = await this.ascAxios("post", `Common/getFloorsByDepartmentIds`, container);
            this.setState({ floorSelect: result.data, floor_id: result.data });
            let value_arr = result.data.map((floor) => floor.value);
            return value_arr;
        } catch (err) {
            throw err;
        }
    }

    async getCommonFloorSelect(container) {
        try {
            return await this.ascAxios("post", `Common/getFloorsByDepartmentIds`, container);
        } catch (err) {
            throw err;
        }
    }

    onMultiSelectChange(event, param) {
        if (Array.isArray(event)) {
            this.setState({
                [param]: event.map((row) => {
                    return row.value;
                })
            });
        }
    }
    onMultiSelectChangeExternal(event, param) {
        if (Array.isArray(event)) {
            /*
                drawarr     : 表示用state変数
                ids         : 全内線グループに所属する全内線番号情報
                selectids   : 現在選択されている内線グループの全内線番号
                unselectids : 現在未選択の内線グループの全内線番号
                inbound_id  : 現在選択してる内線グループのID
                drawlabel   : 各drawarrのlabel要素情報(count表示更新と0の場合括弧削除)
                drawcount   : 各drawarrのcount要素情報(count数値更新)
            */
            let drawarr = JSON.parse(JSON.stringify(this.state.inbound_group_base));
            let ids = JSON.parse(JSON.stringify(this.state.inbound_group_cm51ids));
            let selectids = [];
            let unselectids = [];
            // 選択されているグループID取得
            let inbound_id = event.map((row) => {
                return row.value;
            });
            let drawlabel = "";
            let drawcount = 0;

            // 選択している内線グループの内線情報と選択していないグループ全部の内線情報を仕分け
            for (let cm51idcount = 0; cm51idcount < ids.length; cm51idcount++) {
                if (inbound_id.indexOf(ids[cm51idcount].id) != -1) {
                    // 選択されてる
                    // 何も入ってないならpush
                    if (selectids) {
                        // 何か入ってる+今pushされてる情報の中で存在しない内線ならpush
                        if (
                            selectids.findIndex(
                                ({ cm51_id }) => cm51_id === ids[cm51idcount].cm51_id
                            ) === -1
                        ) {
                            selectids.push(ids[cm51idcount]);
                        }
                    } else {
                        selectids.push(ids[cm51idcount]);
                    }
                } else {
                    // 未選択
                    // 何も入ってないならpush
                    if (unselectids) {
                        // 何か入ってる+今pushされてる情報の中で存在しない内線ならpush
                        if (
                            unselectids.findIndex(
                                ({ cm51_id }) => cm51_id === ids[cm51idcount].cm51_id
                            ) === -1
                        ) {
                            unselectids.push(ids[cm51idcount]);
                        }
                    } else {
                        unselectids.push(ids[cm51idcount]);
                    }
                }
            }
            // 全グループ確認
            for (let allgroup = 0; allgroup < drawarr.length; allgroup++) {
                // カウント初期化
                drawcount = drawarr[allgroup].count;
                // 選択されているか確認
                if (inbound_id.indexOf(drawarr[allgroup].value) === -1) {
                    // 選択されて居ない
                    drawcount = drawarr[allgroup].count;
                    // 現在選択されている内線の数分確認
                    for (let extdata = 0; extdata < unselectids.length; extdata++) {
                        // 現在選択している内線が現在選択している内線グループにあるか確認
                        if (
                            selectids.findIndex(
                                ({ cm51_id }) => cm51_id === unselectids[extdata].cm51_id
                            ) != -1
                        ) {
                            // 保険で0切ったら0固定
                            if (drawcount > 0) {
                                // 存在する場合drawcountを-1する
                                drawcount -= 1;
                            } else {
                                drawcount = 0;
                            }
                        }
                    }
                }
                // 選択されてない場合はスルー
                // 該当のグループ情報のカウントが0の場合変更
                /*
                if(drawcount <= 0)
                {
                    drawlabel = drawarr[allgroup].label;
                }
                else
                {
                    drawlabel = drawarr[allgroup].label + "(" + drawcount + ")";
                }
                */
                //drawarr[allgroup].label = drawlabel;
                drawarr[allgroup].count = drawcount;
            }

            // 最後_データセットして終了
            this.setState({
                [param]: event.map((row) => {
                    return row.value;
                })
            });
            this.setState({
                inboundGroupcount: selectids.length,
                inboundGroupSelect: drawarr
            });
        }
    }

    onCheckBoxChange(event, param) {
        this.setState({ [param]: !this.state[param] });
    }

    onCheckBoxChange_Init(event, param, param2) {
        this.setState({ [param]: !this.state[param] });
        this.setState({ [param2]: "" });
    }

    onRadioChange(event, param) {
        let value = event && event.target && event.target.value ? event.target.value : "";
        this.setState({ [param]: value });
    }

    onFileChange(event, param) {
        let value = event.target.files;
        let size_limit = 0;
        let alert_size = "";
        let alert_message = "";

        switch (param) {
            case "csvFile":
                // 顧客管理の場合
                size_limit = GlobalConst.CSV_IMPORT_SIZE_LIMIT.customer;
                alert_size = this.props.langText.Message.CustomerCsvRowLimit;
                break;
            case 'audioFile':
                // 音声ファイルの場合
                size_limit = GlobalConst.AUDIO_UPLOAD_SIZE_LIMIT;
                alert_size = this.props.langText.Message.AudioSizeLimit;
                break;
            default:
                size_limit = GlobalConst.CSV_IMPORT_SIZE_LIMIT.default;
                alert_size = this.props.langText.Message.CsvSizeLimit;
                break;
        }

        alert_message = this.sprintf(this.props.langText.Message.Upload_sizeLimit,alert_size);
        if(value.length > 0 && value[0].size >= size_limit) {
            event.target.value = "";
            alert(alert_message);
            this.setState({ [param]: "" });
        } else {
            this.setState({ [param]: value });
        }
    }

    // db dateTime to momentTime
    getMomentTime = ({ lag = 9, formatStr = "h", format = "YYYY-MM-DD", date }) => {
        return moment(date).utc().add(lag, formatStr).format(format);
    };

    sprintf = (message, ...args) => {
        args.forEach((arg, key) => {
            message = message.replace(new RegExp("\\{" + key + "\\}", "gi"), arg);
        });

        return message;
    };

    /**
     * メッセージにタグ形式のパラメータを入れて表示(パラメータ別にcssスタイル適用可能)
     * @param {string} message
     * @param {array} args
     * @return {object} 
     **/ 
    sprintfTag = (message, ...args) => {
        let msg = message;
        let unite_arr = [];

        args.forEach((tag, key) => {            
            if (msg.indexOf("{" + key + "}") > 0) {
                let text = msg.substring(0, msg.indexOf("{" + key + "}"));
                unite_arr.push(text);
                unite_arr.push(tag);
                msg = msg.replace(new RegExp("\\{" + key + "\\}", "gi"), '');
                msg = msg.replace(text, '');
            } else if (msg.indexOf("{" + key + "}") === 0) {
                unite_arr.push(tag);
                msg = msg.replace(new RegExp("\\{" + key + "\\}", "gi"), '');
                if (key === (args.length - 1)) {
                    unite_arr.push(msg);
                }
            } else {
                return;
            }
        });

        return <span>{unite_arr}</span>;
    };

    /**
     * エラーメッセージの取得
     * @param {string} code Message.jsで定義したメッセージコードを合わせる
     * @param {array}
     * @returns {string} メッセージ文言
     */
    getErrorString = ({ code, args }) => {
        let msg = "";
        args = args || [];

        if (this.props.langText.Message[code]) {
            msg = this.sprintf(this.props.langText.Message[code], ...args);
        } else {
            switch (code) {
                case 1:
                    msg = this.sprintf(this.props.langText.Message.AuthCheckError, ...args);
                    break;
                case 2:
                    msg = this.sprintf(this.props.langText.Message.DataSelectError, ...args);
                    break;
                case 3:
                    msg = this.sprintf(this.props.langText.Message.DataInsertError, ...args);
                    break;
                case 4:
                    msg = this.sprintf(this.props.langText.Message.DataUpdateError, ...args);
                    break;
                case 5:
                    msg = this.sprintf(this.props.langText.Message.DataDeleteError, ...args);
                    break;
                case 6:
                    msg = this.sprintf(this.props.langText.Message.FindDataError, ...args);
                    break;
                case 10:
                    msg = this.sprintf(
                        this.props.langText.Message.User_InsertRegisteredUserError,
                        ...args
                    );
                    break;
                case 11:
                    msg = this.sprintf(
                        this.props.langText.Message.User_InvalidParameterError,
                        ...args
                    );
                    break;
                case 20:
                    msg = this.sprintf(this.props.langText.Message.SignIn_SignInFailed, ...args);
                    break;
                case 21:
                    msg = this.sprintf(
                        this.props.langText.Message.SignIn_NotMatchCodeOrExpiredError,
                        ...args
                    );
                    break;
                case 22:
                    msg = this.sprintf(
                        this.props.langText.Message.SignIn_NotMatchCodeError,
                        ...args
                    );
                    break;
                case 23:
                    msg = this.sprintf(
                        this.props.langText.Message.SignIn_ExecutionFailedError,
                        ...args
                    );
                    break;
                case 24:
                    msg = this.sprintf(
                        this.props.langText.Message.SignIn_UserNotFoundError,
                        ...args
                    );
                    break;
                case 25:
                    msg = this.sprintf(
                        this.props.langText.Message.SignIn_TooMuchFailsError,
                        ...args
                    );
                    break;
                case 26:
                    msg = this.sprintf(
                        this.props.langText.Message.SignIn_NotConfirmedUserError,
                        ...args
                    );
                    break;
                case 30:
                    msg = this.sprintf(
                        this.props.langText.Message.Customer_overlappedTelNoError,
                        ...args
                    );
                    break;
                case 31:
                    msg = this.sprintf(this.props.langText.Message.Customer_overCsvRow, ...args);
                    break;
                case 32:
                    msg = this.sprintf(this.props.langText.Message.Customer_telPregCheck, ...args);
                    break;
                case 40:
                    msg = this.sprintf(
                        this.props.langText.Message.GroupExtensionNum_overlappedNumberError,
                        ...args
                    );
                    break;
                case 41:
                    msg = this.sprintf(this.props.langText.Message.Extension_SequenceSame, ...args);
                    break;
                case 42:
                    msg = this.sprintf(
                        this.props.childProps.langText.Message.Download_PopupblockCheck,
                        ...args
                    );
                    break;
                case 43:
                    msg = this.sprintf(this.props.langText.Message.Download_Failed, ...args);
                    break;
                case 44:
                    msg = this.sprintf(this.props.langText.Message.File_Download_Failed, ...args);
                    break;
                case 45:
                    msg = this.sprintf(this.props.langText.Message.Password_Validation, ...args);
                    break;
                case 46:
                    msg = this.sprintf(this.props.langText.Message.Password_Error, ...args);
                    break;
                case 47:
                    msg = this.sprintf(
                        this.props.langText.Message.SignIn_UserNotFoundException,
                        ...args
                    );
                    break;
                case 48:
                    msg = this.sprintf(
                        this.props.langText.Message.TooManyRequestsException,
                        ...args
                    );
                    break;
                case 49:
                    msg = this.sprintf(
                        this.props.langText.Message.UserNotConfirmedException,
                        ...args
                    );
                    break;
                case 50:
                    msg = this.sprintf(
                        this.props.langText.Message.PasswordResetRequiredException,
                        ...args
                    );
                    break;
                case 51:
                    msg = this.sprintf(this.props.langText.Message.NoDataDeleteError, ...args);
                    break;
                case "modal":
                    msg = this.sprintf(this.props.langText.Message.ModalTypeError, ...args);
                    break;

                default:
                    msg = `Code: ${code}`;
                    break;
            }
        }

        return msg;
    };

    // get select option
    getSelectOption = (column_name, lang) => selectOption(column_name, lang);

    getCompanyControlParametersOption = (column_name, lang) =>
        CompanyControlParametersOption(column_name, lang);

    ascAxios = async (method, ...arg) => {
        return await axios[method](...arg);
    };

    escapeHtml(unsafe) {
        return unsafe.replace("_", "\\_"); //アンダーバー対応
    }

    onFilterChange = (value, accessor) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex((row) => row.id === accessor);

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }
        value = this.escapeHtml(value);

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: accessor,
                value
            });
        }
        this.table.current.state.page = 0;

        this.setState({
            filtered: my_filtered
        });

        setTimeout(() => {
            this.reactTableRefresh();
        }, 300);
    };

    //ユーザー数取得
    async getUserCntByDepartment(cm13_id) {
        try {
            let res = await this.ascAxios("post", `Common/userSearchByDeparment`, { cm13_id });
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    async createHash(string) {
        try {
            let res = await this.ascAxios("post", `Common/createHash`, { string });
            return res.data;
        } catch (err) {
            throw err;
        }
    }

    async reactTableFetchData(state, instance) {
        this.reactTableObj = {
            state: state,
            instance: instance
        };
        this.setState({ loading: true });
        if (this.reactTableTarget) {
            try {
                let res = await this.ascAxios("post", this.reactTableTarget, {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                    hidden_filtered: state.hidden_filtered,
                    searchData: state.searchData //絞り込みが必要な場合の値
                });
                // findAndCountAll 内で group by を使うと、.count が array で返ってくるバグがある。
                // .count を length で計算し直すことにより、board のページングが正常に作動する。
                let count = 0;
                if (Array.isArray(res.data.count)) {
                    count = res.data.count.length;
                } else {
                    count = res.data.count;
                }
                let data = res.data.rows;

                this.setState({
                    data,
                    page: state.page,
                    pageSize: state.pageSize,
                    pages: Math.ceil(count / state.pageSize),
                    count: count,
                    loading: false
                });

                if (state.filtered && this.reactTableTarget === "/CallMonitoring/SeatView/board") {
                    this.getSummary(state.filtered);
                }
            } catch (e) {
                console.log(e);
                this.setState({
                    data: [],
                    pages: null,
                    count: null,
                    loading: false
                });

                if (
                    e.response &&
                    e.response.data &&
                    e.response.data.code === "SessionStorageError"
                ) {
                    // セッションストレージの取得失敗｡
                    // エラーメッセージは出さずにリロードする
                    window.location.reload();
                    return;
                }

                if (
                    !(e.status && e.status === 401) &&
                    !(e.response && e.response.status && e.response.status === 401)
                ) {
                    this.showErrorObjectMesssage(e, "GetDataError_Server");
                }
            }
        }
    }

    /**
     * 全文検索を実行
     *
     * @param {String} cm12Id
     * @param {Object} filtered
     * @param {Callback} formatForTableDisplay
     * @param {Array} keywords
     * @param {Object} state
     */
    async execFullTextSearch(cm12Id, filtered, formatForTableDisplay, keywords, state = {}) {
        try {
            if (!filtered || !cm12Id || !filtered.find(row => row.id === "display_number")) return false;
            this.setState({
                loading: true,
                timeout: false
            });

            // フィルターのキーワードに条件追加
            if (keywords && keywords.length > 0) {
                for (let i = 0; i < filtered.length; i++) {
                    if (filtered[i].id === "keyword") {
                        filtered[i].value = keywords;
                        break;
                    }
                }
            }

            // 総件数取得
            const count = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/execFullTextSearchCount`,
                { filtered }
            );

            // ページ設定
            const page = state.page || 0;
            const pageSize = state.pageSize || this.state.pageSize || 20;

            // sort設定
            let sorted = Object.keys(state).length > 0 && state.sorted ? state.sorted[0] : {};

            // OpenSearchへ全文検索実行
            const result = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/execFullTextSearch`,
                { cm12Id, filtered, page, pageSize, sorted }
            );

            // データフォーマット関数(callback)の実行
            const data = formatForTableDisplay
                ? formatForTableDisplay(result.data.hits.hits)
                : result;
            
            // 1万件以上は1万件まで表示する
            const limit = 10000;
            const limit_count = count.data > limit ? limit : count.data;

            this.setState({
                data: data,
                page: page,
                pageSize: pageSize,
                pages: Math.ceil(limit_count / pageSize),
                count: count.data,
                loading: false
            });
        } catch (e) {
            let timeout = false;
            if (e.response.data.code === "CallAnalysisTimeOut") {
                timeout = true;
            }
            this.showErrorObjectMesssage(e, "GetDataError_Server");
            this.setState({
                data: [],
                pages: null,
                count: null,
                loading: false,
                timeout
            });
        }
    }

    async getSummary(filtered) {
        try {
            let result = await this.ascAxios("post", "/CallMonitoring/SeatView/boardSummary", { filtered });

            this.setState({
                operationSecAll: this.secToTimePannel(result.data.operationSecAll),
                connectInSecAll: this.secToTimePannel(result.data.connectInSecAll),
                connectOutSecAll: this.secToTimePannel(result.data.connectOutSecAll),
                connectSecAll: this.secToTimePannel(result.data.connectSecAll),
                inboundConnectionCnt: result.data.inboundConnectionCnt,
                outboundConnectionCnt: result.data.outboundConnectionCnt,
                inboundCnt: result.data.inboundCnt,
                outboundCnt: result.data.outboundCnt,
                blocking: false
            });
        } catch (e) {
            this.setState({
                operationSecAll: 0,
                connectInSecAll: 0,
                connectOutSecAll: 0,
                connectSecAll: 0,
                inboundConnectionCnt: 0,
                outboundConnectionCnt: 0,
                inboundCnt: 0,
                outboundCnt: 0
            });
            throw e;
        }
    }

    numberWithCommas = (val) => {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    secToTimePannel = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}${this.props.langText.Body.Time}${minutes
            .toString()
            .padStart(2, "0")}${this.props.langText.Body.Minute}`;
    };

    reactTableRefresh = () => {
        this.reactTableFetchData(this.table.current.state, this.table.current.instance);
    };

    /**
     * 自身の権限範囲が指定のあった権限範囲以上か判定する
     * @return {Boolean}
     */
    getScopeGreaterEqual = (compareObject) => {
        if (this.props.currentPermission.scope_code) {
            const myScope = this.props.currentPermission.scope_code;
            return GlobalConst.SCOPE_OBJECT[myScope].value <= compareObject.value;
        } else {
            return false;
        }
    };

    /**
     * 自身の権限範囲が指定のあった権限範囲以下か判定する
     * @return {Boolean}
     */
    getScopeLessThanEqual = (compareObject) => {
        if (this.props.currentPermission.scope_code) {
            const myScope = this.props.currentPermission.scope_code;
            return GlobalConst.SCOPE_OBJECT[myScope].value >= compareObject.value;
        } else {
            return false;
        }
    };

    /**
     * scope_codeがoem、systemの場合は一覧に会社を表示
     * @param {Array} displayDataList 一覧表示データリスト
     * @param {Array} ArrayData 追加するデータ
     * @param {Number} addPosition 追加する位置
     * @return {Array}
     */
    displayByPermission = (displayDataList, ArrayData, addPosition) => {
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))
            displayDataList.splice(addPosition, 0, ArrayData);
        return displayDataList;
    };

    /**
     * エラーメッセージの作成
     * @param {arrya} error エラーオブジェクト
     * @param {*} defaultMessage デフォルトメッセージ
     */
    showErrorObjectMesssage = (error, defaultMessage = "ProcessingFailedError") => {
        let errorMessage = "";

        if (
            error.response &&
            error.response.data &&
            (error.response.data.code || error.response.data.message)
        ) {
            errorMessage = error.response.data.code || error.response.data.message;
            //サーバーで発生したエラーメッセージを出力(エラーオブジェクト対応)
        } else if (error.message) {
            errorMessage = error.message;
            //クライアントで発生したエラーメッセージを出力(エラーオブジェクト対応)
        } else if (typeof error === "string") {
            //文字列エラーを出力
            errorMessage = error;
        } else {
            errorMessage = defaultMessage;
        }
        if (error.response && error.response.data && error.response.data.args) {
            alert(
                this.sprintf(
                    this.props.langText.Message[errorMessage] ||
                        this.props.langText.Message[defaultMessage],
                    ...error.response.data.args
                )
            );
        } else if (error.args) {
            alert(
                this.sprintf(
                    this.props.langText.Message[errorMessage] ||
                        this.props.langText.Message[defaultMessage],
                    ...error.args
                )
            );
        } else {
            alert(
                this.props.langText.Message[errorMessage] ||
                    this.props.langText.Message[defaultMessage]
            );
        }
    };

    /**
     * @return {string} 00:00:00
     */
    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = Math.floor(secs % 60);

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec
            .toString()
            .padStart(2, "0")}`;
    };

    /**
     * 日付の日数を計算する
     * @param {} date
     * @returns number
     */
    calcDateDays = (start, end) => {
        const startDate = new Date(this.getMomentTime({ date: start, format: "YYYY/MM/DD" }));
        const endDate = new Date(this.getMomentTime({ date: end, format: "YYYY/MM/DD" }));
        return (endDate - startDate) / 86400000;
    };

    /**
     * 期間内のすべての日付を取得
     * @param {date} start_date
     * @param {date} end_date
     * @returns {number}
     */
    getDatesStartToLast = (start_date, end_date) => {
        let result = [];
        let cur_date = new Date(start_date);
        while (cur_date <= new Date(end_date)) {
            result.push(this.getMomentTime({date: cur_date, format: this.props.langText.Body.DateFormat}));
            cur_date.setDate(cur_date.getDate() + 1);
        };
        return result;
    }

    /**
     * 期間内の日数の制限検査
     * @param {date} start_date
     * @param {date} end_date
     * @param {number} limit_cnt
     * @returns {Boolean}
     */
    checkDatesLimit = (start_date, end_date, limit_cnt) => {
        const days = this.calcDateDays(start_date, end_date);
        let return_flag = true;

        if (days < 0 || days >= limit_cnt) {
            return_flag = false;
        }

        return return_flag;
    }

    /**
     * アクセスログの保存
     * @param {date} path
     * @param {date} req_body
     */
    saveAccessLogs = async (req_params) => {
        try {
            let result = await this.ascAxios('post', `/Common/saveAccessLogs`,
                req_params, {
                    timeout: 0 
                }
            );
            return result;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    /**
     * パスワードバリデーション
     * @param {string} param
     * @param {object} state
     * @returns {boolean} 
     */
    passwordValidationHandle = (param, state) => {
        let validation_flag = true;
        let {
            password,
            passwordRe,
            newPassword,
            newPasswordRe,
            passwordResetFlag, // ログインユーザー以外＆内線番号＆オペレーターパスワード変更(ユーザー管理＆内線番号管理＆オペレーター管理)
            oldPassword // ログインユーザーのパスワード変更(ユーザー管理＆ユーザー情報)
        } = this.state;
        // 英数字それぞれ 1 文字ずつ含む 8 文字以上
        let passwordCheck = /^(?=.*?[a-z])(?=.*?[0-9])[\x20-\x7e]{8,}$/;
        let oldPasswordCheck = /(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

        switch (param) {
            case "password":
                validation_flag = passwordCheck.test(state[param]);
                break;

            case "passwordRe":
                validation_flag = (
                    passwordCheck.test(state[param]) &&
                    state[param] === password
                );
                break;

            case 'newPassword':
                if (
                    oldPassword || 
                    this.state[param] || 
                    passwordResetFlag || 
                    passwordResetFlag === undefined
                ) {
                    validation_flag = (
                        passwordCheck.test(this.state[param]) &&
                        this.state[param] !== oldPassword
                    )
                }
                break;

            case 'newPasswordRe':
                if (
                    oldPassword ||
                    newPassword || 
                    this.state[param] || 
                    passwordResetFlag ||
                    passwordResetFlag === undefined
                ) {
                    validation_flag = (
                        passwordCheck.test(this.state[param]) &&
                        this.state[param] === newPassword
                    );
                }
                break;

            case "oldPassword":
                if (
                    this.state[param] || 
                    passwordResetFlag === undefined
                ) {
                    validation_flag = oldPasswordCheck.test(this.state[param]);
                }
                break;

            case "copy":
            case "insert":
            // 内線番号管理画面の一括登録する時
            case "insertBatch":
                validation_flag = (
                    passwordCheck.test(password) &&
                    password === passwordRe
                );
                break;

            case "update":
                if (passwordResetFlag) {
                    validation_flag = (
                        passwordCheck.test(newPassword) &&
                        newPassword === newPasswordRe
                    );
                } else if (oldPassword){
                    // ユーザー管理画面でログインされているアカウントのパスワード設定する時
                    validation_flag = (
                        oldPasswordCheck.test(oldPassword) &&
                        passwordCheck.test(newPassword) &&
                        newPassword === newPasswordRe &&
                        oldPassword !== newPassword
                    );
                }
                break;

            // ユーザー情報のsubmitバリデーション
            case "updateSelfPassword":
                validation_flag = (
                    oldPasswordCheck.test(oldPassword) &&
                    passwordCheck.test(newPassword) &&
                    newPassword === newPasswordRe &&
                    oldPassword !== newPassword
                );
                break;

            // ログイン画面のパスワードをお忘れの方はこちらのsubmitバリデーション
            case "submitNewPassword":
                validation_flag = (
                    passwordCheck.test(newPassword) &&
                    newPassword === newPasswordRe
                );
                break;

            default:
                break;
        }

        return validation_flag;
    }

    /**
     * コメントコピー
     * @param {String} type
     * @param {Object} data 
     * @return {Object}
     */
    copyData =(type, data)=> {
        let {selected} = this.state;
        let returnText = "";
        let copyText = "";
        let user_name = "";
        let voice_time = "";
        let voiceType = type.includes("voice");
        let commentType = type.includes("comment");
        switch (type) {
            case "voice-all":
                copyText = data.map(row=>{
                    if(selected.ct60_call_histories.inout_type === "in"){
                        user_name = (row.inout === "out")? this.props.langText.Body.Respondent : this.props.langText.Body.DestUser;
                    } else if(selected.ct60_call_histories.inout_type === "out"){
                        user_name = (row.inout === "out")? this.props.langText.Body.Respondent : this.props.langText.Body.DestUser;
                    }
                    voice_time = this.secToTime(row.startTime || 0);
                    return (voice_time+"【"+user_name+"】"+"\n"+row.transcript+"\n");
                });
                returnText = copyText.toString().replace(/,/g, '\n');
                break;
            case "voice-each":
                if(selected.ct60_call_histories.inout_type === "in"){
                    user_name = (data.inout === "out")? this.props.langText.Body.Respondent : this.props.langText.Body.DestUser;
                } else if(selected.ct60_call_histories.inout_type === "out"){
                    user_name = (data.inout === "out")? this.props.langText.Body.Respondent : this.props.langText.Body.DestUser;
                }
                voice_time = this.secToTime(data.startTime || 0);
                copyText = voice_time+"【"+user_name+"】"+"\n"+data.transcript+"\n";
                returnText = copyText.toString();
                break
            case "comment-all":
                copyText = data.map(row=>{
                    let created = moment(row.created).format('YYYY/MM/DD HH:mm:ss');
                    return (created+"【"+row.entry_user_name+"】"+"\n"+row.data+"\n");
                });
                returnText = copyText.toString().replace(/,/g, '\n');
                break;
            case "comment-each":
                let created = moment(data.created).format('YYYY/MM/DD HH:mm:ss');
                copyText = created+"【"+data.entry_user_name+"】"+"\n"+data.data+"\n";
                returnText = copyText.toString();;
                break;
            default:
                break;
        }
        if (voiceType){
            this.setState({
                voice_alert_flag: true,
                voice_alert_message: this.props.langText.Message.VoiceTextCopied
            })
        } else if (commentType){
            this.setState({
                comment_alert_flag: true,
                comment_alert_message: this.props.langText.Message.CommentCopied
            })
        }
        
        return navigator.clipboard.writeText(returnText);
    }
    /**
     * toastメッセージ表示
     * @param {String} message
     */
    toast = (message) => {
        toast(message);
    }

    // コール結果・ランク更新
    updateCallResultRankData = async ({id, ct60_id, cm12_id, call_result, call_rank_1, call_rank_2}) => {
        try {
            const result = await this.ascAxios("post", "Common/updateCallResultRank", {
                id,
                ct60_id,
                cm12_id,
                cm64_id: call_result ? call_result.id : null,
                call_rank_1: call_rank_1 ? call_rank_1.id : null,
                call_rank_2: call_rank_2 ? call_rank_2.id : null
            });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataUpdateError");
        }
    }

    // コール結果リスト取得
    getCallResultList = async (company_id, cm61_id) => {
        return await this.ascAxios("post", "Common/getCallResultList", { company_id, cm61_id });
    }

    // コールランクリスト取得
    getCallRankList = async (cm64_id) => {
        try {
            let call_rank_list = (await this.ascAxios("post", "Common/getCallRankList", { cm64_id })).data;
            let call_rank_1_list = call_rank_list.filter(row => row.value.rank_type === 1);
            let call_rank_2_list = call_rank_list.filter(row => row.value.rank_type === 2);

            return [call_rank_1_list, call_rank_2_list];
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    /**
     * 音声テキスト更新
     * @param {Integer} index
     * @param {String} value
     * @param {Object} currentPermission
     * @return {Object}
     */
    updateVoiceData = async (index, value, currentPermission) => {
        try {
            if(!value.trim()){
                //入力内容がない場合、アラートが出る
                return alert(this.props.langText.Message.VoiceTextBlankError);
            } 
            let { selected } = this.state;
            let voice_data = [...this.state["selected"]["text_data_json"]];
            voice_data[index] = {
                ...voice_data[index],
                transcript: value||"",
                modified: moment().tz("Asia/Tokyo").format(),
                update_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                update_user_email: this.props.userInfo.user_id,
            }
            let result = await this.ascAxios("post", `Common/updateVoiceText`, {id: selected.id, voice_data, currentPermission});
            selected.text_data_json = voice_data;

            this.setState({
                selected,
                voice_alert_flag: true,
                voice_alert_message: this.props.langText.Message.VoiceTextUpdated,
                keyword_index_arr:[]
            });
            return result;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataUpdateError");
        }
    }

    /**
     * ct60_idでコール結果・ランク取得
     * @param {String} ct60_id
     */
    getCallResultRankInfo(ct60_id) {
        return this.ascAxios("post", `Common/getCallResultRankByCt60Id`, {ct60_id});
    }

    // 会社パラメータ取得(コール結果・ランク)
    getCompanyControlCallResultRank = async () => {
        try {
            const result = await this.ascAxios("post", "Common/getCompanyControlCallResultRank");

            return result.data;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }
}

