import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import * as GlobalConst from "../../components/AscConstants";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import CallCategoryTagModal from "./CallCategoryTagModal";

export default class CallCategoryTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,
            companySelect: []

            // data item
        };
    }

    getColumnsData() {
        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: 'id',
            filterable: false,
            width: this.props.boardWidth.id,
        });

        
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            itemArr.push({
            Header: this.props.langText.Body.CompanyName,
            filterable: false,
            accessor: 'cm12_companies.company_name',
            });
        }
        
        // itemArr.push({
        //     Header: this.props.langText.Body.DepartmentName,
        //     accessor: 'cm13_departments.department_name',
        //     width: this.props.boardWidth.medium,
        //     Cell: data => {
        //         let str =
        //             (data.row._original.all_department_flag === 'Y')
        //             ?
        //             this.props.langText.Body.AllDepartment
        //             :
        //             data.row._original.cm13_departments.department_name

        //         return (
        //             <div>{str}</div>
        //         )
        //     }
        // });

        itemArr.push({
            Header: this.props.langText.Body.CallCategoryTag,
            filterable: false,
            accessor: 'name',
            Cell: data => {
                return (
                    <span
                        style={{
                            backgroundColor: data.row._original.rgb,
                            padding: '2px 4px',
                            borderRadius: '4px',
                            color: data.row._original.font_rgb
                        }}
                    >
                        {data.value}
                    </span>
                );
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.RealDescription,
            accessor: 'description',
            filterable: false,
        });

        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.Control,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize='xsmall'
                            onClick={this.modalShow('update', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='pencil'/>
                        </Button>
                    )
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize='xsmall'
                            onClick={this.modalShow('read', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='eye-open'/>
                        </Button>
                    )
                }

                if (this.props.currentPermission.create) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("copy", data.row._original)}
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize='xsmall'
                            onClick={this.modalShow('delete', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='minus'/>
                        </Button>
                    )
                }

                return (
                    <Row className='text-center'>
                        {rowData}
                    </Row>
                )
            }
        });

        return itemArr;
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();

        let setData = {
            id: param && param.id
                ? param.id
                : '',
            company_id: param && param.cm12_id
                ? param.cm12_id
                : '',
            company_name: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : '',
            name: param && param.name && modalType !== "copy"
                ? param.name
                : '',
            description: param && param.description
                ? param.description
                : '',
            rgb: param && param.rgb
                ? param.rgb
                : '#D8D8D8',
            font_rgb: param && param.font_rgb
                ? param.font_rgb
                : '#000000',
            modalType,
            show: true
        };

        try {
            if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
                await this.setCommonCompanySelect(this.reactContainerPath.split("/")[1]);
            } else if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                setData.company_id = this.props.userInfo.company_id;
                setData.company_name = this.props.userInfo.company_name;
            } 
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }

        this.setState(setData);
    };

    onClickHandle = modalType => async event => {
        let {
            id,
            company_id,
            name,
            rgb,
            font_rgb,
            description
        } = this.state;

        this.blockUI();
        try {
            switch (modalType) {
                case "insert":
                case "copy":
                    await this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                        company_id,
                        name,
                        rgb,
                        font_rgb,
                        description
                    });

                    alert(this.props.langText.Message.DataInsertSuccess);
                    break;
                case "update":
                    await this.ascAxios("post", `${this.reactContainerPath}/update`, {
                        id,
                        company_id,
                        name,
                        rgb,
                        font_rgb,
                        description
                    });
                        
                    alert(this.props.langText.Message.DataUpdateSuccess);
                    break;
                case "delete":
                    await this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                        id
                    });

                    alert(this.props.langText.Message.DataDeleteSuccess);
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);   
            this.showErrorObjectMesssage(err);                 
        }
        
        this.reactTableRefresh();
        this.setState({
            show: false
        });
    };

    validationHandle = param => {
        let nowState = null;
        let {
            company_id,
            name
        } = this.state;

        switch (param) {
            case "company_id":
                nowState = this.state[param] 
                    ? null
                    : "error";
                break;
            case "name":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;
            case "insert":
            case "update":
            case "copy":
                nowState = (
                    company_id &&
                    name
                );
                break;
            case "delete":
                nowState = true;
                break;
            default:
                break;
        }

        return nowState;
    };

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/CallCategory", name: this.props.langText.Body.CallCategory },
                        { name: this.props.langText.Body.CallCategoryTag },
                    ]}
                />
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="call-category-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>
                <CallCategoryTagModal
                    state={this.state}
                    propSetState={this.propSetState}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                    onClick={this.onClickHandle}
                    onTextChange_Limit={this.onTextChange_Limit}
                    onSelectChange={this.onSelectChange}
                    validationHandle={this.validationHandle}
                    getScopeGreaterEqual={this.getScopeGreaterEqual}
                />
            </React.Fragment>
        );
    }
}
