import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css";

export default class CallAnalysisTemplateCheckModal extends Component {
    getTemplateInfoItem = () => {
        let {
            propState,
            langText,
            getFilterData
        } = this.props;

        let itemArr = [];

        // テンプレート名
        itemArr.push(
            <InlineForm.static
                key = "templateName"
                controlId = "templateName"
                label = {langText.Body.TemplateName}
            >
                {propState.templateName}
            </InlineForm.static>
        );

        // 会社名
        itemArr.push(
            <InlineForm.static
                key = "companyName"
                controlId = "companyName"
                label = {langText.Body.CompanyName}
            >
                {(getFilterData("cm12_id")).label}
            </InlineForm.static>
        )

        // 外線番号
        itemArr.push(
            <InlineForm.static
                key = "ExternalNumber"
                controlId = "ExternalNumber"
                label = {langText.Body.ExternalNumber}
            >
                {(getFilterData("display_number")).label}
            </InlineForm.static>
        );

        // 相手先番号
        itemArr.push(
            <InlineForm.static
                key = "destTelNumber"
                controlId = "destTelNumber"
                label = {langText.Body.DestUserNumber}
            >
                {(getFilterData("dest_tel_no")) || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // 期間指定
        itemArr.push(
            <InlineForm.static
                key = "periodSetting"
                controlId = "periodSetting"
                label = {langText.Body.PeriodSettring}
            >
                {propState.periodSetting.label}
            </InlineForm.static>
        );

        // 開始日時
        if (propState.periodSetting.value === "fixed") {
            itemArr.push(
                <InlineForm.static
                    key = "start_date"
                    controlId = "start_date"
                    label = {langText.Body.ExtensionStartDateTime}
                >
                    {`${propState.start_datetime ? propState.start_datetime.format("YYYY/MM/DD").toString() : null} ${propState.start_ji.label}:${propState.start_hun.label}`}
                </InlineForm.static>
            )
    
            // 終了日時
            itemArr.push(
                <InlineForm.static
                    key = "end_date"
                    controlId = "end_date"
                    label = {langText.Body.ExtensionEndDateTime}
                >
                    {`${propState.end_datetime ? propState.end_datetime.format("YYYY/MM/DD").toString() : null} ${propState.end_ji.label}:${propState.end_hun.label}`}
                </InlineForm.static>
            )
        }

        // 発信区分
        itemArr.push(
            <InlineForm.static
                key = "InOutType"
                controlId = "InOutType"
                label = {langText.Body.InOutType}
            >
                {
                    getFilterData("inout_type") === "all" ? langText.Body.All
                    : getFilterData("inout_type") === "in" ? langText.Body.Inbound
                    : langText.Body.Call                   
                }
            </InlineForm.static>
        );

        // 確認
        itemArr.push(
            <InlineForm.static
                key = "Check"
                controlId = "Check"
                label = {langText.Body.Check}
            >
                {
                    getFilterData("check_flag") === "all" ? langText.Body.All
                    : getFilterData("check_flag") === "true" ? langText.Body.Confirmed
                    : langText.Body.Unconfirmed                   
                }
            </InlineForm.static>
        );

        // キーワード
        itemArr.push(
            <InlineForm.static
                key = "Keyword"
                controlId = "Keyword"
                label = {`${langText.Body.Keyword} (${getFilterData("keyword_term").label})`}
            >
                {
                    (propState.keywords &&
                    propState.keywords.length > 0) ?
                    propState.keywords.map((row, index) => {
                        return (
                            <span
                                key={index}
                                style={{
                                    backgroundColor: row.isNot ? '#FF8787' : '#D8D8D8',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    marginRight: '8px'
                                }}
                            >
                                {row.label}
                            </span>
                        )
                    }) :
                    langText.SelectOption.Unset
                }
            </InlineForm.static>
        )

        // コメント
        itemArr.push(
            <InlineForm.static
                key = "comment"
                controlId = "comment"
                label = {langText.Body.Comment}
            >
                {getFilterData("comment") || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        return itemArr;
    }

    render() {
        return (
            <div className="CallAnalysisTemplateCheckModal">
                <AscModal
                    state = {this.props.state}
                    propSetState = {this.props.propSetState}
                    langText = {this.props.langText}
                    validationHandle = {this.props.validationHandle}
                    customFunc={() => {
                        if (this.props.state.modalType === "templateDelete") this.props.onClick(this.props.state.modalType)
                        else this.props.onClick()
                    }}
                >
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.getTitle()}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            {this.getTemplateInfoItem()}
                        </Panel.Body>
                    </Panel>
                </AscModal>
            </div>
        );
    }
}
