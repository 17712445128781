import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Glyphicon, Col} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import chara_limit from "../../chara_limit";
import "../Elements/FromGroup/form_select.css"
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import * as GlobalConst from "../../components/AscConstants";

export default class CallCategoryTagModal extends Component {
    getTagInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onSelectChange,
            validationHandle,
            getScopeGreaterEqual,
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key="company_id"
                    controlId="company_id"
                    label={langText.Body.CompanyName}
                    type="select"
                    onChange={e => onSelectChange(e, "company_id")}
                    options={state.companySelect}
                    value={state.company_id}
                    isClearable={true}
                    validationState={validationHandle("company_id")}
                    placeholder={langText.SelectOption.Placeholder}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="company_name"
                    controlId="company_name"
                    label={langText.Body.CompanyName}>
                    {state.company_name}
                </InlineForm.static>
            );
        }

        // タグ名
        if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
            itemArr.push(
                <InlineForm
                    key="name"
                    controlId="name"
                    label={langText.Body.CallCategoryTagName}
                    type="text"
                    onChange={e => onTextChange_Limit(e, "name", chara_limit.Tag_Name)}
                    validationState={validationHandle("name")}
                    value={state.name}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="name"
                    controlId="name"
                    label={langText.Body.CallCategoryTagName}>
                    {state.name}
                </InlineForm.static>
            );
        }

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `${ state.rgb }`,
                    display: 'block'
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                }
            },
        });

        const font_styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `${ state.font_rgb }`,
                    display: 'block'
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                }
            },
        });

        const image_styles = reactCSS({
            'default': {
                swatch: {
                    padding: '2px 4px',
                    borderRadius: '4px',
                    background: `${ state.rgb }`,
                    color: `${ state.font_rgb }`
                },
            }
        });

        // カラー
        if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
                itemArr.push(
                    <Col xs={12} md={12} className="tag-color-style">
                        <Col xs={3} md={3} className="padding-left-0">
                            <InlineForm
                                key="TagColor"
                                controlId="TagColor"
                                label={langText.Body.TagColor}
                            >
                                <div className="col-sm-1">
                                    <div style={ styles.swatch } onClick={e => this.handleClick("back_ground")}>
                                        <div style={ styles.color } />
                                    </div>
                                    { 
                                        state.tag_display_color_picker ?
                                        <div style={ styles.popover }>
                                            <div style={ styles.cover } onClick={e => this.handleClose("back_ground")}/>
                                            <SketchPicker color={ state.rgb } onChange={e =>this.handleChange("back_ground",e)} />
                                        </div> : 
                                        null 
                                    }
                                </div>
                            </InlineForm>
                        </Col>
                        <Col xs={3} md={3} className="padding-left-0">
                            <InlineForm
                                key="TagFontColor"
                                controlId="TagFontColor"
                                label={langText.Body.TagFontColor}
                            >
                                <div className="col-sm-1">
                                    <div style={ font_styles.swatch } onClick={e => this.handleClick("font")}>
                                        <div style={ font_styles.color } />
                                    </div>
                                    { 
                                        state.tag_font_display_color_picker ? 
                                        <div style={ font_styles.popover}>
                                            <div style={ font_styles.cover } onClick={e => this.handleClose("font")}/>
                                            <SketchPicker color={ state.font_rgb } onChange={e => this.handleChange("font",e)} />
                                        </div> : 
                                        null 
                                    }
                                </div>
                            </InlineForm>
                        </Col>
                    </Col>
                );
        } else {
            itemArr.push(
                <Col xs={12} md={12} className="tag-color-style">
                    <Col xs={3} md={3} className="padding-left-0">
                        <InlineForm.static
                            key="TagColor"
                            controlId="TagColor"
                            label={langText.Body.TagColor}
                        >
                            <div className="col-sm-1">
                                <span>
                                    <span style={ styles.swatch }>
                                        <span style={ styles.color } />
                                    </span>
                                </span>
                            </div>
                        </InlineForm.static>
                    </Col>
                    <Col xs={3} md={3} className="padding-left-0">
                        <InlineForm.static
                            key="TagFontColor"
                            controlId="TagFontColor"
                            label={langText.Body.TagFontColor}
                        >
                            <div className="col-sm-1">
                                <span>
                                    <span style={ font_styles.swatch }>
                                        <span style={ font_styles.color } />
                                    </span>
                                </span>
                            </div>
                        </InlineForm.static>
                    </Col>
                </Col>
            );
        }

        // タグイメージ
        itemArr.push(
            <InlineForm
                key="TagImage"
                controlId="TagImage"
                label={langText.Body.TagImage}
            >
                <div className="col-sm-12 margin-top-03 padding-left-0">
                    <span style={ image_styles.swatch }>
                        {state.name ? state.name : langText.Body.TagImageEx}
                    </span>
                </div>
            </InlineForm>
        );

        // 説明
        if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
            itemArr.push(
                <InlineForm
                    key="description"
                    controlId="description"
                    label={langText.Body.RealDescription}
                    componentClass="textarea"
                    rows="3"
                    value={state.description}
                    onChange={e => onTextChange_Limit(e, "description", charaLimit.External_description)}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="description"
                    controlId="description"
                    label={langText.Body.RealDescription}
                >
                    {state.description}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    handleClick = (type) => {
        let {
            state,
            propSetState,
        } = this.props;

        if (type === "font") {
            propSetState({ tag_font_display_color_picker: !state.tag_font_display_color_picker })
        } else if (type === "back_ground") {
            propSetState({ tag_display_color_picker: !state.tag_display_color_picker })
        }

    };
    
    handleClose = (type) => {
        let {
            propSetState,
        } = this.props;
        
        if (type === "font") {
            propSetState({ tag_font_display_color_picker: false })
        } else if (type === "back_ground") {
            propSetState({ tag_display_color_picker: false })
        }
    };

    handleChange = (type,color) => {
        let {
            propSetState
        } = this.props;

        if (type === "font") propSetState({ font_rgb: color.hex });
        else if (type === "back_ground") propSetState({ rgb: color.hex });
    };

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.CallCategoryTagSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getTagInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
