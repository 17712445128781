export default {
    Gateway: "GW管理",
    Pbx: "PBX管理",
    ExtensionNumber: "内線番号管理",
    ExternalNumber: "外線番号管理",
    OutboundReject: "発信規制管理",
    InboundGroup: "内線グループ管理",
    GroupExtensionNumber: "グループ番号管理",
    InboundReject: "着信拒否管理",
    BusinessCalendar: "営業時間管理",
    VoiceFile: "音声管理",
    Company: "会社管理",
    Department: "拠点管理",
    Floor: "フロア管理",
    User: "ユーザー管理",
    CallHistory: "通話履歴",
    Customer: "顧客管理",
    ManualDL: "マニュアルダウンロード",
    OmniPhone: "Omni Phone",
    OmniCusCon: "Omni-CustomerControl",
    Permission: "権限管理",
    Script: "スクリプト管理",
    AutoCallList: "リスト管理",
    Summary: "集計",
    Monitoring: "PBXモニタリング",
    CallAnalysis: "通話品質分析",
    BusinessManagement: "業務管理",
    Operator: "オペレーター管理",
    CallMonitor: "ACDモニタリング",
    CallMonitoring: "通話モニタリング",
    Operator: "オペレーター管理",
    AutoCall: "自動発信",
    DownloadHistory: "ダウンロード履歴管理",
    CallCategory: "通話分類"
};
