export default {
    Wait: "少々お待ちください。",
    // モーダルタイトル
    Title: "タイトル",
    InsertTitle: "新規登録",
    InsertBatchTitle: "一括登録",
    UpdateTitle: "編集",
    DeleteTitle: "削除",
    CopyTitle: "コピー",
    ReadTitle: "閲覧",
    Version: "バージョン",
    ErrorTitle: "エラー内容",
    NewInsert: "新規作成",
    StatisticsTitle: "統計",
    HistoryStatisticsTitle: "履歴統計",
    ChangePasswordTitle: "パスワード変更",
    DownloadHistoryDetail: "ダウンロード履歴詳細",
    Redo: "再実行",
    callRecordingPlayTitle: "通話録音再生",

    // ボタン
    Insert: "登録",
    Add: "追加",
    Update: "更新",
    BatchDelete: "一括削除",
    Delete: "削除",
    Cancel: "キャンセル",
    Set: "設定",
    Check: "確認",
    UnConfirmed: "未確認",
    Upload: "アップロード",
    Submit: "送信",
    CodeSubmit: "認証コード送信",
    Verify: "ユーザー認証",
    ResetPassword: "パスワード再設定",
    LogIn: "ログイン",
    LogGingIn: "ログイン中",
    LogOut: "ログアウト",
    Search: "検索",
    Copy: "コピー",
    Read: "閲覧",
    Close: "閉じる",
    Miss_Inbound: "未着信",
    Save: "保存",
    Insert2: "挿入",
    AdSet: "詳細設定",
    InsertTelNo: "番号追加",
    Back: "戻る",
    OmniPhoneLogOut: "オムニフォンログイン状態リセット",
    ChangePassword: "パスワード変更",
    CancelLocking: "ロック解除",
    SaveCallResultRank: "コール結果・ランク保存",

    // 一般項目
    Id: "ID",
    Control: "操作",
    Memo: "メモ",
    CompanyName: "会社名",
    DepartmentName: "拠点名",
    Lang: "言語",
    Text: "テキスト",
    Number: "番号",

    // 特定項目
    AllDepartment: "全拠点",
    AllFloor: "全フロア",
    Audio: "音声",
    AudioInfo: "音声ファイル設定",
    AudioSetting: "音声ファイル設定",
    AudioName: "音声名",
    Auth: "権限",
    AutoCreate: "自動生成",
    AutoUpdateInterval: "自動更新間隔",
    BackNumber: "裏番号",
    BindPort: "BIND Port",
    Created: "登録日",
    CompanySetting: "会社設定",
    CustomerCompanyName: "会社名(顧客)",
    CustomerInfo: "顧客情報",
    CustomerInsert: "顧客登録",
    CustomerName: "顧客名",
    CustomerNameCc: "顧客名(漢字)",
    CustomerNameKana: "顧客名(カナ)",
    DateFormat: "YYYY/MM/DD",
    DataHourFormat: "YYYY/MM/DD HH:mm",
    Department: "拠点",
    DepartmentInfo: "拠点情報",
    DepartmentSetting: "拠点設定",
    DisplayNumber: "表示番号",
    Download: "ダウンロード",
    EmailAddress: "メールアドレス",
    EmailAddressLogIn: "Email address",
    EntryUserName: "登録者",
    ExtensionNumber: "内線番号",
    ExtensionNumberInfo: "内線情報",
    ExtensionNumberNotFound: "内線情報なし",
    ExtensionNumberSetting: "内線番号設定",
    ExtensionNumberNum: "内線番号数",
    ExtensionNumberType: "内線種類",
    ExternalNumberInfo: "外線情報",
    ExternalNumberSetting: "外線番号設定",
    ExternalNumber: "外線番号",
    ExternalNumberName: "外線番号名称",
    ExternalNumberList: "外線番号情報リスト",
    LineType: "回線種別",
    FileUpload: "ファイルアップロード",
    FirstNameCc: "姓",
    FirstNameKana: "セイ",
    FloorSetting: "フロア設定",
    FtpPort: "FTP Port",
    GatewayInfo: "GW情報",
    GatewaySetting: "GW設定",
    GatewayName: "GW名",
    GatewayRegisterId: "GW登録ID",
    GatewayRegisterPW: "GW登録PW",
    GatewayIpaddress: "GWIP",
    GatewayPort: "GWポート",
    GatewayType: "GW区分",
    BackNumberDial: "裏番号発信",
    BackNumberDialUse: "裏番号で発信する",
    DisplayNumberDialUse: "表示番号で発信する",
    GroupName: "グループ名",
    GroupType: "グループ種類",
    HostName: "ホスト名",
    InboundGroupInfo: "内線グループ情報",
    InboundGroupSetting: "内線グループ設定",
    InboundGroupName: "内線グループ",
    InboundGroupType: "内線タイプ",
    GroupExtensionNumberInfo: "グループ番号情報",
    GroupExtensionNumberSetting: "グループ番号設定",
    InboundRejectInfo: "着信拒否情報",
    InboundRejectSetting: "着信拒否設定",
    InboundRejectRule: "着信拒否ルール",
    InboundSetting: "着信設定",
    Dispensing: "着信コントロール",
    DispensingSetting: "着信コントロール設定",
    InsertCount: "登録番号数",
    LastLoginDate: "最終ログイン日",
    LastNameCc: "名",
    LastNameKana: "メイ",
    Length2: "数字2桁",
    Length3: "数字3桁",
    Modified: "更新日",
    OmniURL: "オムニURL",
    OperatorId: "オペレーターID",
    OperatorName: "オペレーター名",
    OperatorInfo: "オペレーター情報",
    OperatorDetail: "オペレーター詳細",
    OperatorNotFound: "オペレーター情報なし",
    OutboundNotFound: "発信規制情報",
    OutboundRejectSetting: "発信規制設定",
    OutboundRejectRule: "発信規制ルール",
    Password: "パスワード",
    PasswordLogIn: "Password",
    PasswordNew: "新パスワード",
    PasswordNewRe: "新パスワード(再入力)",
    PasswordOld: "旧パスワード",
    PasswordRe: "パスワード(再入力)",
    Pbx: "PBX",
    PbxInfo: "PBX情報",
    PbxSetting: "PBX設定",
    PbxName: "PBX名",
    Prefix: "プレフィックス",
    PrivateIP: "ローカルネット",
    PublicIP: "外部IPアドレス",
    Record: "録音",
    SpeechSynthesis: "音声合成",
    SpeechSynthesisTranslate: "音声合成（翻訳）",
    Tag: "タグ",
    Telno: "電話番号",
    Tlanslate: "翻訳",
    RequiredTelno: "電話番号（必須）",
    UpdateUserName: "更新者",
    User: "ユーザー",
    UserId: "ユーザーID",
    UserInfo: "ユーザー情報",
    UserInsert: "ユーザー登録",
    UserName: "ユーザー名",
    UserNameCc: "ユーザー名 (漢字)",
    UserNameKana: "ユーザー名 (カナ)",
    UserLockedFlag: "ロック状態",
    VerificationCode: "認証コード",
    WssPort: "WSS Port",
    SettingType: "設定タイプ",
    InOutType: "発着区分",
    DestUserNumber: "相手先番号",
    DestUserName: "相手先名",
    StartTime: "開始時間",
    AnswerdTime: "応答時間",
    EndTime: "終了時間",
    TalkingTime: "通話時間",
    DayTalkingTime: "1日の通話時間",
    Result: "結果",
    Floor: "フロア",
    FloorName: "フロア名",
    VoiceLogPassword: "通話録音認証パスワード",
    VoiceLogPasswordNow: "[現]通話録音認証パスワード",
    VoiceLogPasswordNew: "[新]通話録音認証パスワード",
    VoiceLogPasswordNewRe: "[新]通話録音認証パスワード(再入力)",
    VoiceLogExpire: "録音データ保管期限",
    OmniPhoneincomingNumberColor: "オムニフォン着信時(背景色)",
    OmniPhoneincomingFontColor: "オムニフォン着信時(文字色)",
    OmniPhoneExampleExplanation: "オムニフォン着信時のイメージ",
    OmniPhoneResetDefaultColorSet: "リセット",
    OmniPhoneResetBeforeColorSet: "以前の色に戻す",
    OmniPhoneAppVersion: "オムニフォンアプリバージョン",
    OemUsed: "OEM使用",
    OemSetting: "OEM設定",
    CsvImport: "CSV投入",
    CsvUpload: "CSVアップロード",
    RunType: "動作",
    Second: "秒",
    DetailSettings: "高度な詳細",
    LoginPasswordExpireDays: "ログインパスワード有効期限(日数)",
    IsSetLoginPasswordExpireDays: "有効期限を設定する(OFFの場合、無期限となります)",
    NoExpire: "有効期限なし",
    ChineseCharacter: "漢字",
    Katakana: "カタカナ",
    PrefixSystemUseNumber: "490～499はシステム予約番号のため使用できません。",
    SearchCondition: "検索条件",
    CallResultRank: "コール結果・ランク",
    CallRank: "ランク",

    //カスコンログイン画面
    LoginUserPasswordExpired: "パスワードの期限が切れています。下記画面より変更をお願いします。",
    PasswordMismatch: "パスワードが一致しません。",
    PasswordDuplicateOld: "旧パスワードと新パスワードは別の値を設定してください。",

    //PBX管理
    InstanceId: "インスタンスID",
    region: "リージョン",

    // 営業カレンダー
    Sunday: "日",
    Monday: "月",
    Tuesday: "火",
    Wednesday: "水",
    Thursday: "木",
    Friday: "金",
    Saturday: "土",
    Holiday: "祝",
    DayBeforeHoliday: "祝前",
    DayOff: "休日",
    UnknownDay: "曜日不明",
    Date: "日付",
    Hour: "時",
    Minute: "分",
    Day: "選択日",
    Class: "区分",
    BusinessCalendar: "営業カレンダー",
    BusinessCalendarSetting: "営業カレンダー設定",
    BusinessCalendarName: "営業カレンダー名",
    BusinessHourSetting: "営業時間設定",
    BusinessHour: "営業時間",
    BusinessDay: "営業日",
    BusinessHourIn: "営業時間内",
    BusinessHourOut: "営業時間外",
    Behavior: "挙動",
    BehaviorSetting: "挙動設定",
    IrregularSetting: "イレギュラー設定",
    BusinessHourInBehavior: "営業時間内の挙動",
    BusinessHourOutBehavior: "営業時間外の挙動",
    AddIrregular: "イレギュラー追加",
    Inbound: "着信",
    Busy: "BUSY",
    Transfer: "転送",
    ExternalTransfer: "外線転送",
    PlayVoice: "音声再生",
    MegaCall: "MEGAコール",
    WeekOrnament: "曜日",
    HolidayOrnament: "日",
    AllCheck: "一括選択",
    AllUnCheck: "一括解除",
    AllSelected: "全選択されています",
    Unset: "指定しない",
    Cautioncompanyselect: "会社を選択してください。",
    Cautiondatadiff: "選択した曜日のデータが異なりますので、前のデータは上書きされます。",
    Cautionyoubiselect: "曜日を選択してください。",
    IrregularBatchSetting: "イレギュラー一括設定",
    CheckInput: "入力内容確認",
    BusinessCalendarSelect: "営業カレンダー選択",
    Type: "種類",
    exceptionPlay: "特定音声再生",
    exceptionPlaySetting: "特定音声再生時間設定",
    exceptionPlayHour: "特定音声再生時間",
    exceptionPlayVoiceFile: "特定音声再生時の再生音声",
    BusinessName: "業務名",
    ExternalNumberCount: "外線番号数",
    BusinessOperatorCount: "オペレーター数",
    CSVExport: "CSVエクスポート",

    // 業務&スキルランク管理
    BusinessManagement: "業務管理",
    SkillRank: "スキルランク",
    SkillCsvImport: "CSVインポート",
    ExchangeTableDataToCsv: "既存のデータを上書き",
    SkillStatus: "状態",
    AssignDepartmentAndFloor: "業務割り当て",
    CsvMemoSample: "メモを入力してください",
    CsvTemplateFileName: "業務スキルランク投入テンプレート.csv",

    // 会社設定
    OutboundPrefix: "発信開始番号",
    AddingOptionUsingId: "【ID】",
    AddingOptionUsingName: "【名称】",
    AddingOptionUsingNumber: "【番号】",
    LineTypeChannel: "回線別チャネル数設定",
    LineChannelNumber: "最大チャネル数",

    // 外線番号設定
    ChannelNumber: "最大着信数",
    WaitNumber: "最大待ち呼数",
    SoundNumber: "待ち呼音声",
    WaitCallTimeOut: "待ち呼制限時間",
    WaitCallTimeOutCheckBox: "待ち呼制限時間を設定する",
    WaitCallTimeOutSeconds: "待ち呼制限時間（秒）",
    WaitCallTimeOutSound: "待ち呼制限時間超過音声",
    AbjSound: "通話成立時特殊音声再生",
    BeforeDialSound: "通話開始前音声",
    BeforeDialSoundPlay: "通話開始前音声再生",
    CallOverSound: "待ち呼超過音声",
    CallOverAction: "待ち呼超過時の着信ステータス",
    CallOverActionExp: "通話履歴上で、待ち呼超過分の通話結果に反映されます。",
    ExtenCount: "合計内線数(重複除く)",
    ExtenCountOver_1: "登録可能な内線数(",
    ExtenCountOver_2: ")を超過しています。",
    PrefixDuplicate: "このプレフィックスは現在使用されています",
    EmptyPrefix: "登録可能プレフィックス：",
    ListAppointInboundSetting: "リスト指定着信設定",
    ListAppointInbound: "リスト指定着信",
    List: "リスト",
    OutOfListInboundAction: "リスト外着信時の動作",
    OutOfListInboundPlayBack: "リスト外着信時の再生音声",
    UnconnectedSetting: "全内線未接続時の設定",
    UnconnectedAction: "全内線未接続時の動作",
    UnconnectedSoundPlayBack: "全内線未接続時の再生音声",
    TimeoutSec: "応答待ち時間",
    TimeoutAudio: "応答待ち時間超過時の再生音声",
    TimeoutSetting: "応答待ち時間設定",
    DialRestrictionFlagSetting: "通話中の内線への着信",
    ACDSetting: "ACD機能",
    ACDSettingDescription: "業務管理で設定したルールに基づいて着信を自動的に振り分けます。",
    AnonymousSetting: "非通知番号からの着信設定",
    AnonymousAction: "非通知番号からの着信動作",
    AnonymousSoundPlayBack: "非通知番号からの着信時の再生音声",
    CallAnalysisTitle :"通話品質分析機能",
    CallAnalysisCheckBox :"当番号の通話を、通話品質分析機能の対象とする",
    SchedueExtensionGroup: "スケジュール内線グループ",
    ExtensionStartDateTime: "開始日時",
    ExtensionEndDateTime: "終了日時",
    ScheduleExtenCount: "合計内線数",
    ControlCallOverAction: "待ち呼超過時の着信動作",
    DefaultAudio: "デフォルト音声",

    // 内線番号設定
    DefaultExternalNum: "デフォルト発信番号",
    UpdateAllExternalNum: "発信番号一括更新",
    Available: "有り",
    None: "無し",
    ExtensionName: "名称",
    MonitoringFlg: "モニタリング権限",
    Codec: "音声圧縮コーデック",
    PasswordSettingAttention: "オムニフォンモバイル内線の場合のみ設定してください。",
    CheckDelete: "削除内容確認",

    // グループ番号設定
    GroupExtensionNumberName: "グループ番号名",
    GroupExtensionNumber: "グループ番号",
    GroupExtensionDepartmentName: "着信内線_拠点名",
    GroupExtensionFloorName: "着信内線_フロア名",
    ExtensionNumberDuplicate: "この内線番号は現在使用されています",

    // customer
    CustomerCsvImport: "CSV投入",
    CustomerFormatDownload: "CSVテンプレートダウンロード",
    CustomerCsvCompanyName: "顧客会社名",
    CustomerCsvUserFirstName: "顧客姓",
    CustomerCsvUserLastName: "顧客名",
    CustomerCsvUserFirstNameKana: "顧客姓(カナ)",
    CustomerCsvUserLastNameKana: "顧客名(カナ)",
    CustomerCsvUserMailAddress: "顧客メールアドレス",
    CustomerCsvTel: "電話番号",
    CustomerCsvTel2: "電話番号2",
    CustomerCsvTel3: "電話番号3",
    CustomerCsvTel4: "電話番号4",
    CustomerCsvTel5: "電話番号5",
    CustomerCsvTel6: "電話番号6",
    CustomerCsvTel7: "電話番号7",

    CustomerBoardStart: "開始日",
    CustomerBoardEnd: "終了日",
    CustomerBoardPartner: "相手先番号",
    CustomerBoardExternal: "内線番号",
    CustomerBoardExtension: "外線番号",

    //通話履歴
    Today: "今日",
    CallHistory: "通話結果",
    CallHistoryDownload: "通話履歴ダウンロード",
    IncomingNoAnswer: "不在入電",
    Template: "テンプレート",
    StartDate: "開始日",
    EndDate: "終了日",
    SearchResult: "検索結果：",
    CallHistoryExpired: "保管期限切れ",
    CallInfo: "通話情報",
    ResponseReview: "応対評価",
    Score: "総合評価",
    TalkListen: "OP：相手先比率",
    Other: "その他",
    VoiceReview: "音声評価",
    Frequency: "基本周波数",
    AccentStrength: "音声のメリハリ",
    TalkSpeed: "会話速度",
    OperatorVoice: "OP音声",
    CustomerVoice: "相手先音声",
    Talk: "Talk",
    Listen: "Listen",
    SilenceCount: "無音回数",
    TalkConflictCount: "重なり回数",
    RallyingCount: "会話の往復回数",
    FillerCount: "フィラー回数",
    TimeCount: "回",
    Op: "OP",
    Customer: "相手先",
    Hz: "Hz",
    TextSecond: " 文字 / 秒",
    OperatorNull: "オペレーター情報なし",

    //通話履歴CSV
    CallHistoryCsvName: "通話履歴",
    ExternalNumberMemo: "外線番号メモ",
    StartDateTime: "通話開始時間",
    AnswerDateTime: "通話成立時間",
    EndDateTime: "通話終了時間",
    BillSec: "通話時間（秒）",

    // 音声情報
    VoiceListUpdate: "更新者:",
    VoiceListCreate: "作成者:",

    // ユーザー管理
    DownloadFlag: "通話録音ダウンロード",
    DownloadTrue: "ダウンロード可能",
    DownloadFalse: "ダウンロード不可",
    UserCsvTemplateFileName: "ユーザー情報投入テンプレート.csv",
    UserCsvBelongInfo: "ユーザー所属情報",

    PartialMatch: "部分一致",
    ForwardMatch: "前方一致",

    UserLockedStatus: "ロック",

    UserCompanySetting: "会社・拠点（フロア）を設定する",
    UserDepartmentSetting: "拠点（フロア）を設定する",
    UserFloorSetting: "フロアを設定する",

    //スクリプト管理
    Script: "スクリプト",
    ScriptInfo: "スクリプト情報",
    ScriptName: "スクリプト名",
    CurrentVersionHeader: "適用Ver",
    CurrentDescription: "適用バージョンメモ",
    LatestVersionHeader: "最新Ver",
    LatestDescription: "最新バージョンメモ",
    VersionSetting: "バージョン設定",
    VersionSelect: "バージョン選択",
    VersionCheck: "バージョン確認",
    CheckTelNo: "確認用電話番号",
    Description: "メモ",
    CurrentVersion: "適用バージョン",
    LatestVersion: "最新バージョン",
    VersionMemo: "バージョンのメモ",
    Call: "発信",
    ScriptTelNo: "電話番号　例）07012345678",
    CurrentVersionLabel: "（現在適用バージョン）",
    TemplateUpdate: "テンプレート設定",
    UseScriptSetting: "スクリプト個別設定",
    UseScriptSettingCheckBox: "スクリプト個別設定を利用する",

    // 権限管理
    BasePermissionName: "基本権限",
    PermissionName: "権限名",
    UserCount: "ユーザー数",
    PermissionInfo: "権限情報",
    permissionDetail: "権限詳細",
    PermissionContent: "権限内容",
    PermissionScope: "権限範囲",
    PermissionAll: "全権限",
    PermissionRead: "閲覧",
    PermissionCreate: "作成",
    PermissionEdit: "編集",
    PermissionDelete: "削除",
    PermissionExport: "出力",
    PermissionPlayback: "録音再生",
    PermissionContentList: {
        Gateway: "GW管理",
        Pbx: "PBX管理",
        ExternalNumber: "外線番号管理",
        ExtensionNumber: "内線番号管理",
        InboundGroup: "内線グループ管理",
        GroupExtensionNumber: "グループ番号管理",
        OutboundReject: "発信規制管理",
        InboundReject: "着信拒否管理",
        BusinessCalendar: "営業時間管理",
        VoiceFile: "音声管理",
        Company: "会社管理",
        Department: "拠点管理",
        Floor: "フロア管理",
        User: "ユーザ管理",
        Customer: "顧客管理",
        Script: "スクリプト管理",
        CallHistory: "通話履歴",
        Permission: "権限管理",
        SeatView: "シートビュー",
        AutoCallList: "リスト管理",
        Summary: "集計",
        Monitoring: "モニタリング",
        CallAnalysis: "通話品質分析",
        BusinessManagement: "業務管理",
        CallMonitor: "ACDモニタリング",
        Operator: "オペレーター管理",
        CallMonitoring: "通話モニタリング",
        AutoCall: "自動発信",
        DownloadHistory: "ダウンロード履歴管理",
        Bookmark: "ブックマーク",
        CallCategory: "通話分類"
    },
    PermissionScopeList: {
        floor: "フロア内",
        department: "拠点内",
        company: "会社内",
        oem: "OEM内",
        system: "システム内"
    },
    addingOption : "追加オプション",

    // スクリプトテンプレート
    ScriptBlockSetting: "{0}ブロック設定",
    ScriptStart: "開始",
    ScriptPlay: "再生",
    ScriptQuestion: "質問",
    ScriptNumberInput: "番号入力",
    ScriptTransfer: "転送",
    ScriptRecord: "録音",
    ScriptCount: "カウント",
    ScriptJump: "ジャンプ",
    ScriptSMS: "SMS送信",
    ScriptIncoming: "着信",
    ScriptTemplate: "テンプレート",
    ScriptTemplateCreate: "テンプレート作成",
    ScriptNewVersionCreate: "新しいバージョン作成",
    ScriptCreateVersion: "作成元バージョン",
    ScriptAudioSyntheticSetting: "音声合成設定",
    ScriptAudioSyntheticType: "音声合成の種類",
    ScriptAudioClass: "音声分類",
    ScriptCustomColumn: "カスタム項目",
    ScriptAnswerNumber: "回答番号",
    ScriptRepeat: "繰り返し",
    ScriptAccept: "有効",
    ScriptAcceptQuestion: "有効質問",
    ScriptNumLength: "桁数",
    ScriptInputDataCheck: "入力後の確認",
    ScriptInputDataCheckSetting: "入力後の確認設定",
    ScriptCallAudio: "呼び出し音声",
    ScriptTimeoutAudio: "タイムアウト音声",
    ScriptTransferTelNumber: "転送先電話番号",
    ScriptTransferSeatNumber: "転送先席数",
    ScriptTransferTimeout: "転送タイムアウト",
    ScriptRecordCancelNumber: "録音停止番号",
    ScriptMaxRecordTime: "最大録音時間",
    ScriptSmsTitle: "SMSタイトル",
    ScriptSmsText: "SMS本文",
    ScriptTimeoutSetting: "タイムアウトの設定",
    ScriptOthersNumberSetting: "その他の番号の設定",
    ScriptNextSession: "次のブロック",
    ScriptZoomToFit: "画面調整",
    Male: "男性",
    Female: "女性",

    //自動発信リスト
    AutoCallList: "リスト管理",
    ListName: "リスト名",
    ListCount: "リスト件数",
    Count: "件",
    AutoCallListManagement: "リスト管理",
    ItemNameManagement: "項目管理",
    ItemNameCount: "有効：",
    ItemNameDeleteCount: "無効：",
    DuplicateTelno: "電話番号重複チェック",

    //シートビュー機能
    SeatView: "シートビュー",
    TotalOperationTime: "総稼働時間",
    TotalOutboundTime: "総発信時間",
    TotalOutboundCnt: "総発信数",
    TotalOutboundConnectionCnt: "総発信通話接続数",
    OutboundConnectionRate: "発信接続率",

    TotalInboundTime: "総着信時間",
    TotalInboundCnt: "総着信数",
    TotalInboundConnectionCnt: "総着信通話接続数",
    InboundConnectionRate: "着信接続率",

    TotalCallTime: "総通話時間",
    TotalCallCnt: "総発着信数",
    TotalCallConnectionCnt: "総発着信通話接続数",
    CallConnectionRate: "発着信接続率",

    OperationTime: "稼働時間",
    TotalCallTimeAndCallRate: "総通話時間",
    TotalWaitingTime: "総待機時間",
    OutboundCnt: "発信数",
    InboundCnt: "着信数",
    SecCnt: "秒数",
    RingTime: "呼出時間",
    WaitingTime: "待機時間",
    Status: "ステータス",

    StatusAnswered: "通話中",
    StatusEnd: "通話終了",
    StatusCalling: "発信中",
    StatusWisper: "ウィスパリング中",
    StatusMonitor: "モニタリング中",
    StatusNull: "起動なし",

    Time: "時間",
    DefaultTime: "00時間00分",

    //集計
    Summary: "集計",
    MonitoringSummary: "モニタリング集計",
    MonitoringSummaryDescription: "サーバー負荷情報（CPU使用率・メモリ使用率）の確認ができます。",

    //モニタリング
    MonitoringDownload: "モニタリング履歴ダウンロード",
    OperationTimeSearch: "稼働時間検索",
    CpuUsage: "CPU使用率",
    MemoryUsage: "メモリ使用率",
    MonitoringSetting: "モニタリング設定",
    UseMonitoring: "モニタリング使用",
    NotUseMonitoring: "モニタリング未使用",
    DateTime: "日時",
    MonitoringTypeList: {
        CPUUtilization: "CPU使用率",
        mem_used_percent: "メモリ使用率"
    },

    SettingInfoDownload: "設定情報ダウンロード",
    SearchDownload: "検索結果ダウンロード",
    DetailDownload: "詳細データダウンロード",
    CpuUsageMax: "CPU使用率(最大)",
    CpuUsageMin: "CPU使用率(最小)",
    CpuUsageAvg: "CPU使用率(平均)",
    MemoryUsageMax: "メモリ使用率(最大)",
    MemoryUsageMin: "メモリ使用率(最小)",
    MemoryUsageAvg: "メモリ使用率(平均)",
    ChUsedSummary: "使用CH数集計",
    ChUsedSummaryDescription: "最大同時使用CH数の確認ができます。",
    Max: "最大",
    Average: "平均",
    Median: "中央",
    CallCountSummary: "発着信数集計",
    CallCountSummaryDescription:
        "最大発着信数の確認ができます。条件を変更することで集計単位の変更が可能です。",
    SummaryTimeZone: "時間帯",
    SummaryTotal: "合計",
    SearchDate: "検索日",
    WaitCallSummary: "待ち呼集計",
    WaitCallSummaryDescription: "外線番号ごとの待ち呼集計情報が確認できます。",
    SummaryTargetDay: "集計日",
    WaitCallCount: "待ち呼数",
    WaitCallCountTotal: "累計待ち呼数",
    WaitCallDiscardCount: "途中放棄数",
    WaitCallMaxTime: "最大待ち呼時間",
    WaitCallDiscardAvgTime: "平均放棄時間",

    //通話品質分析
    FromTo: "～",
    VoiceQualityAnalysis: "通話品質分析",
    Keyword: "キーワード",
    HitCount: "ヒット数：",
    Comment: "コメント",
    CountingPeriod: "集計期間",
    CallAnalysisDownload: "分析履歴ダウンロード",
    CallAnalysisCsvName: "分析履歴",
    VoiceText: "音声テキスト",
    AnalysisContent: "分析内容",
    CallHistoryName: "通話履歴",
    All: "全て",
    Confirmed: "確認済",
    Unconfirmed: "未確認",
    Updated: "編集済み",
    DestUser: "相手先",
    Respondent: "応対者",
    PlaySpeed: "再生速度",
    Play: "再生",
    Before5sec: " 5秒前",
    After5sec: "5秒後 ",
    AutoScroll: "自動スクロール",
    Edit: "編集",
    KeywordCount: "ヒット数",
    CallAnalysisTemplateInsert: "テンプレート登録",
    CallAnalysisTemplateUpdate: "テンプレート編集",
    CallAnalysisTemplateDelete: "テンプレート削除",
    CallAnalysisTemplateCopy: "テンプレートコピー",
    TemplateName: "テンプレート名",
    PeriodSettring: "期間指定",
    CallContentDownload: "通話内容ダウンロード",
    DownloadHistory: "ダウンロード履歴",
    FileName: "ファイル名",
    CallHistoryId: "通話履歴ID",

    //ACDモニタリング
    GiveUpTimeRange: "途中放棄検索範囲",
    CallMonitorInboundAlertTime: "着信アラート時間",
    CallMonitorRefreshTime: "リスト更新時間",
    FullScreen: "最大化",
    OriginalScreen: "最大化解除",
    ExternalNumberSelect: "外線番号選択",
    DepartmentSelect: "拠点選択",
    ExternalMemo: "外線メモ",
    BusinessMemo: "業務名",
    MaxWaitCount: "最大待ち呼",
    WaitCount: "待ち呼",
    GiveUp: "途中放棄",
    MaxWaitTime: "最大待ち呼時間",
    AvgGiveUpTime: "平均放棄時間",
    ACDCall: "ACD呼",
    TotalLogin: "総ログイン数",
    RestTimeList: {
        Rest05: "休05分",
        Rest30: "休30分",
        Rest45: "休45分",
        Rest60: "休60分"
    },
    ExtensionStatus: "ステータス",
    ExtensionMemo: "名前",
    ExtensionStatusList: {
        LogIn: "ログイン",
        Inbound: "着信中",
        Outbound: "発信中",
        Call: "通話中",
        Ready: "受電可",
        Work: "後処理",
        Rest: "離席",
        Rejection: "着信拒否",
        Hold: "保留中"
    },
    RestMemo: "離席理由",

    // オペレーター管理
    OperatorIdDuplicate: "このオペレーターIDは既に登録されています。",
    OperatorCsvTemplateFileName: "オペレーター情報投入テンプレート.csv",

    OperatorCsvBelongInfo: "オペレーター所属情報",
    OperatorCsvUpload: "CSVアップロード",

    // 通話モニタリング
    CallMonitoring: "通話モニタリング",
    WaitStatuses: "待ち呼一覧",
    WaitStatusesDescription: "現在の待ち状況が確認できます。",
    SeatViewDescription: "現在の内線毎の状況が確認できます。",
    ExternalNumberName: "外線番号名称",
    CallWaitingTime: "待ち呼滞在時間",
    IncomingCallDate: "着信日時",
    CallWaitingLeaveDate: "待ち呼離脱日時",
    CallWaitingSearchDate: "検索日時",
    CallWaitingAlertTime: "待ち呼アラート時間",
    StatusCallWaiting: "待ち呼中",

    // 自動発信
    AutoCall: "自動発信",
    PredictiveCall: "プレディクティブコール",
    InsertPredictiveCall: "プレディクティブコール登録",
    UpdatePredictiveCall: "プレディクティブコール編集",
    PredictiveCallDescription: "プレディクティブコールの実行･管理します。",
    PredictiveCallHistory: "履歴",
    PredictiveCallHistoryDetail: "履歴明細",
    AutoCallTaskName: "タスク名",
    AutoCallStatus: "ステータス",
    AutoCallCallStartTime: "開始時間",
    AutoCallAutoStopTime: "自動停止時間",
    AutoCalledCount: "リスト消費(繰返含)",
    AutoCallWaitExtensionNumber: "待機内線数",
    AutoCallExtensionNumber: "通話数",
    AutoCallConnectCount: "接続数",
    AutoCallGiveUp: "接続放棄数",
    AutoCallMagnification: "発信倍率",
    AutoCallTimeOut: "架電タイムアウト時間(秒)",
    AutoCallRetrySetting: "再発信回数設定※不在の電話に対して",
    AutoCallRetry: "再発信回数(回)",
    AutoCallRetryInterval: "再発信間隔(時)",
    AutoCallConnectedSetting: "接続後",
    AutoCallConnectedPlay: "接続後音声再生",
    AutoCallConnectedTimeOut: "接続後タイムアウト(秒)",
    AutoCallConnectedTimeOutPlay: "接続後タイムアウト音声再生",
    AutoCallReportMail: "レポートメール",
    AutoCallMailTo: "宛先",
    AutoCallMailCc: "CC",
    AutoCallMailBcc: "BCC",
    AutoCallStart: "発信開始時",
    AutoCallStop: "発進停止時",
    AutoCallLoopList: "リスト周回時",
    AutoCall1HourSummary: "1時間ごとの集計",
    AutoCallErrorMail: "エラーメール",
    AutoCallErrorStop: "異常停止時",
    AutoCallConnectError: "接続率異常時",
    AutoCall1Hour: "1時間あたり",
    AutoCallLess: "%以下",
    AutoCallGiveUpError: "接続放棄異常時",
    AutoCallUp: "呼以上",
    AutoCallTotalOutboundCnt: "総発信数",
    AutoCallOutboundRate: "発信倍率",
    AutoCallOutboundTimeout: "架電タイムアウト",
    AutoCallReOutboundTime: "再発進間隔",
    AutoCallReOutboundMax: "発信回数上限",
    AutoCallConnectedTimeout: "接続後タイムアウト",
    AutoCallTotal: "総計",
    AutoCallStatisticsInfo: "統計情報",
    AutoCallConnectRate: "接続通話率",
    AutoCallDisconnectRate: "接続放棄率",
    AutoCallAvgCallTime: "平均通話時間",
    AutoCallAvgWaitTime: "平均接続通話待ち時間",
    AutoCallAvgGiveUpTime: "平均接続放棄時間",
    AutoCallAvgAnswerTime: "平均応答時間",
    AutoCallRealTimeInfo: "瞬間情報",
    AutoCallConnectWaitCount: "通話待ち接続数",
    AutoCallExternalNumberNonNotification: "発信番号非通知",
    AutoCallExternalLimitOutbound: "同時発信数上限",
    AutoCallExternalMaxOutbound: "最大同時発信数上限",
    AutoCallRejectList: "発信除外リスト",
    AutoCallRejectListDuplicated: "「リスト」と「発信除外リスト」が重複しています。",
    RightArrow: "➡",
    DownArrow: "⬇",
    SendOut: "送信する",
    NotSendOut: "送信しない",
    ChannelLimitError: "登録されているチャネル数がありません。",
    PredictiveStatusCall: "発信中",
    PredictiveStatusStop: "停止中",
    PredictiveStatusUncall: "未発信",
    AutoCallStartDateTime: "架電開始日時",
    AutoCallConnectDateTime: "顧客応答日時",
    AutoCallOperatorAnsweredDateTime: "OP応答日時",
    AutoCallEndDateTime: "切電日時",
    AutoCallConnectTime: "顧客応答時間",
    AutoCallOperatorAnsweredTime: "OP応答時間",
    AutoCallDisConnectTime: "接続放棄時間",

    // 通話分析集計
    CallAnalysisSummary: "通話分析集計",
    CallAnalysisSummaryDescription: "通話分析の集計情報が確認できます",
    CallAnalysisSummaryViewMode: "ビューモード",
    CallAnalysisSummaryViewModeList: {
        CallAnalysisSummaryOverview: "全体",
        CallAnalysisSummaryCompare: "比較",
        CallAnalysisSummaryByExtensionNumber: "内線別",
        CallAnalysisSummaryByOperator: "OP別",
        CallAnalysisSummaryByDay: "日別",
        CallAnalysisSummaryByWeek: "週別",
        CallAnalysisSummaryByMonth: "月別"
    },
    Quotation: ">",
    LessThan: "<",
    Clear: "クリア",
    SummaryCondition: "集計条件",
    CallTimeZone: "通話時間帯",
    Colon: ":",
    SupportMemo: "応対メモ",
    AllInclude: "全てを含む",
    DetailCondition: "詳細条件",
    TalkRate: "OP会話比率",
    TalkSpeedDifference: "会話速度差",
    SummaryMethod: "集計方法",
    AverageValue: "平均値",
    MedianValue: "中央値",
    NoMinLimit: "下限無し",
    NoMaxLimit: "上限無し",
    TotalCallCount: "総発着信数",
    CallType: "通話種別",
    DayAvg: "1日の平均",
    Absent: "不在",
    CallCount: "通話回数",
    DayCallCount: "1日の通話回数",
    AvgCallCount: "発着信数平均",
    MedCallCount: "通話回数中央値",
    OutboundRate: "発信接続率",
    InboundRate: "着信接続率",
    CallAnalysis2: "通話分析",
    SpeakingDirection: "レーダーチャート",
    Accent: "音声のメリハリ",
    FrequencyDifference : "基本周波数の差",
    AccentDifference : "音声のメリハリの差",
    CharactersPerSecond: "文字/秒",
    CountPerMinute: "回 / 分",
    Percent: "%",
    PerCount: "/ 回",
    DayMed: "1日の中央値",
    ScoreZero: "0点",
    LoadingData: "集計中",
    CallStop: "停止",
    ScoreAll: "/100",
    CompareA: "比較対象A",
    CompareB: "比較対象B",
    Than: "より",
    Low: "低い",
    Same: "同一",
    High: "高い",
    CallResultFilter: "コール結果絞り込み条件",
    CallResultExclusion: "コール結果除外条件",
    CallResult: "コール結果",
    TotalCallResultCount: "総件数：",
    UnderTriangle: "▼",
    RightTriangle: "▶",
    Unregistered: "未登録",

    // 通知
    NotificationTitle: "お知らせ",

    // ダウンロード履歴管理
    DownloadHistory: "ダウンロード履歴",
    Creator: "作成者",
    Item: "項目",
    ExecTime: "実行日時",
    ExpireTime: "有効期限",
    CreateCondition: "【作成条件】",
    DownloadDatetime: "ダウンロード日時",
    DownloadPerson: "ダウンロード者",
    FileCreating: "作成中",
    FileCreated: "作成済",
    FileFailed: "失敗",
    CreateDatetime: "作成日時",
    HistoryDetail: "履歴詳細",
    CallContent: "通話内容",
    DownloadHistoryExpired: "期限切れ",
    ReCreate: "再作成",
    ErrorMessage: "エラーメッセージ",

    // ブックマーク
    Bookmark: "ブックマーク",
    Bookmarked: "ブックマーク済み",
    BookmarkRegister: "ブックマークします",

    Exec: "実行",

    // 通話分類
    CallCategory: "通話分類",
    CallCategoryTag: "通話分類タグ",
    AutoTagRule: "タグ自動付与",
    CallCategoryTagDescription: "通話分類タグを作成できます。",
    AutoTagRuleDescription: "タグ自動付与ルールを作成できます。",
    RealDescription: "説明",
    RuleName: "ルール名",
    AutoTagRule: "自動付与ルール",
    CallCategoryTagName: "通話分類タグ名",
    TagColor: "タグカラー",
    TagFontColor: "文字カラー",
    TagImage: "タグイメージ",
    TagImageEx: "通話分類タグ名を入力してください。",
    CallCategoryTagSetting: "通話分類タグ設定",
    AutoTagRuleSetting: "自動付与ルール設定",
    RuleCount: "回数",
    KeywordAssociation: "キーワード組合",
    Or: "いずれか",
    And: "すべて",
    Not: "NOT",
    SpeechPerson: "発話者",
    SpeechOperator: "オペレーター",
    SpeechExistence: "発話の有無",
    Speeched: "発話した",
    NotSpeeched: "発話しなかった",
    GiveTags: "付与するタグ",
    Business: "業務",
    CountMore: "回以上",
    CountLess: "回未満",
    NotCallAnalysisExternal: "【外線番号】"
};
