import React from "react";
import {Panel} from "react-bootstrap";
import Component from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";


export default class CallCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };
    }

    panelOnClick = (path) => {
        this.props.historyPush({
            pathname: path,
        });
    };

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.CallCategory }]} />
                <div className="flex-style">
                    <Panel
                        bsStyle="info"
                        bsSize="large"
                        className="summary-select"
                        onClick={() => this.panelOnClick("CallCategory/CallCategoryTag")}
                    >
                        <Panel.Heading>
                            <Panel.Title componentClass="h1">
                                {this.props.langText.Body.CallCategoryTag}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className="body-summary-select">
                            {this.props.langText.Body.CallCategoryTagDescription}
                        </Panel.Body>
                    </Panel>

                    <Panel
                        bsStyle="info"
                        bsSize="large"
                        className="summary-select"
                        onClick={() => this.panelOnClick("CallCategory/AutoTagRule")}
                    >
                        <Panel.Heading>
                            <Panel.Title componentClass="h1">
                                {this.props.langText.Body.AutoTagRule}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className="body-summary-select">
                            {this.props.langText.Body.AutoTagRuleDescription}
                        </Panel.Body>
                    </Panel>
                </div>
            </React.Fragment>
        );
    }
}
