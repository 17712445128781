import React, {Component} from "react";
import {Panel, Radio, Row, Col, FormControl, Glyphicon} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import AscAudio from "../../containers/Elements/Audio/Audio";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";

export default class VoiceFileModal extends Component {
    getAudioInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onSelectChange,
            onFileChange,
            onRadioChange,
            validationHandle,
            getScopeGreaterEqual
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "update")
            && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))
        {
            itemArr.push(
                <InlineForm
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.companyId}
                    onChange = {e => onSelectChange(e, "companyId")}
                    options = {state.companySelect}
                    validationState = {validationHandle("companyId")}
                    placeholder = {!state.buttondisabledArr.company_id[0]
                        ? <Glyphicon glyph="refresh" className="spinning"/>
                        : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "audioName"
                    controlId = "audioName"
                    label = {langText.Body.AudioName}
                    type = "text"
                    value = {state.audioName}
                    onChange = {e => onTextChange_Limit(e, "audioName",charaLimit.voice_audioName)}
                    validationState = {validationHandle("audioName")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "audioName"
                    controlId = "audioName"
                    label = {langText.Body.AudioName}>
                    {state.audioName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "audioData"
                    label = {langText.Body.Audio}> 
                    {/* ラジオボタンの選択肢が唯一なので非表示
                    <Col sm={12}>
                        <Radio
                            key = "audio_type_radio"
                            name = "audio_type_radio"
                            onChange = {e => onRadioChange(e, 'audioType')}
                            checked = {state.audioType === "fileUpload"}
                            value = "fileUpload">
                            {langText.Body.FileUpload}
                        </Radio>
                    </Col> */}
                    <Row>
                    <Col
                        sm = {12}
                        disabled = {state.audioType !== "fileUpload"}
                        className="fileRow">
                        <Col sm = {5}>
                            <FormControl
                                type = "file"
                                onChange = {e => onFileChange(e, "audioFile")}
                                accept = "audio/*"/>
                        </Col>
                        <Col sm = {1}>
                            {
                                state.audioFile.length ?
                                (
                                    <AscAudio
                                        key = {0}
                                        type = "file"
                                        data = {state.audioFile}
                                        className = "control-button"
                                        langText = {langText}
                                        />
                                )
                                :
                                (
                                    <AscAudio
                                        key = {1}
                                        type = "url"
                                        data = {state.audioURL}
                                        className = "control-button"
                                        langText = {langText}/>
                                )
                            }
                        </Col>
                    </Col>
                    </Row>
                </InlineForm>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}
                    type = "text"
                    value = {state.memo}
                    onChange = {e => onTextChange_Limit(e, "memo",charaLimit.voice_memo)}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    controlId = "memo"
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.AudioSetting}
                        </Panel.Title>
                        {(this.props.state.modalType === "delete"
                        && this.props.state.voiceValidationFlag === false
                        && this.props.state.voiceUsingData.length > 0)
                        && <>
                            <div className="voice-using">
                                {this.props.langText.Message.AudioDeleteError}
                            </div>
                            <div className="voice-using-list">
                                {this.props.state.voiceUsingData}
                            </div>
                        </>
                        }
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getAudioInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}