import React, {Component} from "react";
import {Panel, FormGroup, Button, Checkbox, Row, Col, Glyphicon , Radio, FormControl} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css";
import CommonTable from "../Elements/Table/CommonTable";

export default class DownloadHistoryModal extends Component {
    getColumnsData = () => {
        let {
            langText
        } = this.props;
        let columns = [];

        columns.push({
            Header: langText.Body.DownloadDatetime,
            sortable: false,
            accessor: "download_datetime",
        });
        columns.push({
            Header: langText.Body.DownloadPerson,
            sortable: false,
            accessor: "name",
        });

        return columns;
    }

    // キーワード表示
    lineMold = (parts) => {
        let lineText = null;
        let lineElements = null;

        if (parts[1]) {
            let [term, content] = parts[1].split("：");

            if (content) {
                let items = content.split(" ").map(item => {
                    let [text, style] = item.split(";");

                    return { text, style };
                });

                lineText = `${this.props.langText.Body.Keyword}${term}：`;
                lineElements = items.map((item, idx) => {
                    return (
                        item.text &&
                        <span
                            key={idx}
                            style={{
                                backgroundColor: item.style === "true" ? '#FF8787' : '#D8D8D8',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                marginRight: '8px'
                            }}
                        >
                            {item.text}
                        </span>
                    );
                });
            }
        }

        return { lineText, lineElements };
    }

    getModalInfo = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            userInfo,
            table,
            tableHeight,
            modalTableHandle
        } = this.props;

        let itemArr = [];

        switch (state.modalType) {
            case "downloadHistoryDetail":
                // ダウンロード履歴詳細
                itemArr.push(
                    <Row>
                        <Col xs={12} md={12}>
                            <CommonTable
                                className="download-history-modal"
                                talbeRef={table}
                                manual="manual"
                                style={{height: tableHeight-160}}
                                columns={this.getColumnsData()}
                                onFetchData={(state) => {
                                    modalTableHandle(state);
                                }}
                                data={state.modalTableData} 
                                pages={state.modalTablePages}
                                loading={state.loading}
                                previousText={langText.Table.PreviousText}
                                nextText={langText.Table.NextText}
                                loadingText={langText.Table.LoadingText}
                                noDataText={langText.Table.NoDataText}
                                rowsText={langText.Table.RowsText}
                            />
                        </Col>
                    </Row>
                )
                break;
            case "redo":
                // 再実行
                let formattedText = state.conditionDisplay 
                    ? state.conditionDisplay.split("\n").map((line, index) => {
                        // キーワードの場合、テンプレート確認モーダルと統一するため加工
                        let parts = line.split("キーワード");
                        if (parts[1]) {
                            let content = this.lineMold(parts);

                            return (
                                <p key={index}>
                                    {content.lineText}
                                    {content.lineElements}
                                </p>
                            );
                        } else {
                            return <p key={index}>{line}</p>
                        }
                    }) 
                    : <p>{langText.Body.None}</p>;
                itemArr.push(
                    <Panel bsStyle="info">
                        <Panel.Body>
                            <div className="message">
                                <p>{langText.Message.ReDownloadMessage}</p>
                                <p className="padding-top-05">{langText.Body.CreateCondition}</p>
                                {formattedText}
                                <p className="text-danger">{langText.Message.DownloadDeadline}</p>
                            </div>
                            <Col sm={12} md={12} className="padding-left-0 padding-top-05">
                                <Col sm={12} md={3} lg={2} className="padding-left-0 margin-top-05 width-auto">
                                    {langText.Body.FileName}
                                </Col>
                                <Col sm={12} md={9} lg={9} className="padding-left-0">
                                    <InlineForm
                                        key="fileName"
                                        controlId="fileName"
                                        type="text"
                                        value={state.fileName}
                                        onChange={e => onTextChange_Limit(e, "fileName", charaLimit.File_Name)}
                                        placeholder={`${userInfo.user_name_sei + userInfo.user_name_mei} + ${langText.Body.CreateDatetime}`}
                                    />
                                </Col>
                            </Col>
                        </Panel.Body>
                    </Panel>
                )
                break;
            case "errorContent":
                // エラー内容
                itemArr.push(
                    <Panel bsStyle="info">
                        <Panel.Body>
                            <FormControl
                                id="errTextarea"
                                componentClass="textarea"
                                rows="8"
                                value={state.errorMessage}
                                readOnly={true}
                            />
                        </Panel.Body>
                    </Panel>
                )
                break;
            default:
                break;
        }

        return itemArr;
    }


    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={() => this.props.onClick}>
                    {this.getModalInfo()}
            </AscModal>
        );
    }
}
